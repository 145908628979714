import React from "react"
import styled from "styled-components"
import {
  ContentfulRichTextGatsbyReference,
  renderRichText,
  RenderRichTextData,
} from "gatsby-source-contentful/rich-text"
import { CenteredMaxContentWidth } from "../shared/StyleMixins"
import { device } from "../shared/Device"

export type RichTextProps = {
  data: {
    richTextField: RenderRichTextData<ContentfulRichTextGatsbyReference>
  }
}

export const RichText: React.FC<RichTextProps> = ({ data }) => {
  const { richTextField } = data

  return <StyledSection>{renderRichText(richTextField)}</StyledSection>
}

const StyledSection = styled.div`
  ${CenteredMaxContentWidth};
  padding-right: var(--site-main-gutter-mobile);
  padding-left: var(--site-main-gutter-mobile);
  margin-bottom: 3.9rem;
  white-space: nowrap;

  @media ${device.laptop} {
    min-width: 10rem;
    padding-right: 0;
    padding-left: 0;
    :nth-child(4) {
      grid-area: 2/1/2/4;
    }
  }
`
