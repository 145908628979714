import React from "react"
import styled, { css } from "styled-components"
import Sticker from "../components/Sticker"
import { device } from "../shared/Device"
import { ContentfulRichText, GatsbyImageType } from "../shared/ContentfulTypes"
import { SubscriptionCard } from "../components/SubscriptionCard"

type SubscriptionsTypes = {
  heading: string
  whatsIncludedHeading: string
  includedItems: [
    { fullSize: boolean; heading: string; image: GatsbyImageType }
  ]
  infoText: ContentfulRichText
  image: GatsbyImageType
}

type Props = {
  data: {
    heading: string
    sticker: string
    subscriptions: SubscriptionsTypes[]
  }
}
export const Subscription: React.FC<Props> = ({ data }) => {
  const { heading, sticker, subscriptions } = data

  return (
    <StyledSection>
      <HeadingWrapper>
        <Heading>{heading}</Heading>
        <StickerWrapper>
          <Sticker sticker={sticker} />
        </StickerWrapper>
      </HeadingWrapper>
      {subscriptions.map((subscription, i) => {
        return <SubscriptionCard subscription={subscription} key={i} />
      })}
    </StyledSection>
  )
}

const StyledSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const HeadingWrapper = styled.div`
  position: relative;
`

const StickerWrapper = styled.div`
  position: absolute;
  width: 13.25rem;
  height: 13.25rem;
  top: -13rem;
  left: -1rem;
  transform: rotate(10deg);
  z-index: 99;
  pointer-events: none;

  @media ${device.tabletM} {
    width: 18.25rem;
    height: 18.25rem;
    top: -17rem;
    left: -1rem;
  }

  @media ${device.laptop} {
    width: 12.8rem;
    height: 12.8rem;
    left: -5rem;
    top: -6rem;
  }
`

const Heading = styled.h2`
  text-align: center;
  margin-bottom: 4.125rem;
  max-width: 50rem;
`
