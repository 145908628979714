import React, { useCallback, useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { useResizeDetector } from "react-resize-detector"
import UrbanLocation from "../components/Location"
import NewsCard from "../components/NewsCard"
import { ContentfulImage, GatsbyImageType } from "../shared/ContentfulTypes"
import {
  CenteredMaxContentWidth,
  PillButton,
  StepButtonWrapper,
  StepButton,
} from "../shared/StyleMixins"
import { device } from "../shared/Device"
import IconChevron from "../images/icon__chevron.svg"
import { Components } from "../utils/SectionSelector"

export interface ILocation {
  title: string
  __typename: string
  locationImage: ContentfulImage
  buttonTextOne: string
  buttonTextTwo: string
  buttonOneLinksTo: string
  buttonTwoLinksTo: string
  heading: string
}

type Props = {
  data: { heading: string; slides: ILocation[]; randomOrder: boolean }
}

export const Slider: React.FC<Props> = ({ data }) => {
  const { slides, randomOrder } = data

  const [orderedSlides, setOrderedSlides] = useState<ILocation[]>()
  const buttonWrapperRef = useRef<HTMLDivElement>(null)

  function scrollWrapperBy(direction: "back" | "forward") {
    const wrapper = ref?.current
    if (wrapper) {
      const slides = wrapper.querySelectorAll(".slide-wrapper").length
      if (slides > 0) {
        const distance =
          direction === "back"
            ? -wrapper.scrollWidth / slides
            : wrapper.scrollWidth / slides
        wrapper.scrollBy({ top: 0, left: distance, behavior: "smooth" })
      }
    }
  }

  const randomizeSlides = (array: ILocation[]) => {
    let arrayClone = [...array]
    let randomizedArray = []
    let currentIndex = array.length
    let randomIndex

    while (currentIndex != 0) {
      randomIndex = Math.floor(Math.random() * currentIndex)
      currentIndex--

      randomizedArray.push(arrayClone[randomIndex])
      arrayClone.splice(randomIndex, 1)
    }
    setOrderedSlides(randomizedArray)
  }

  useEffect(() => {
    if (randomOrder) {
      randomizeSlides(slides)
    } else {
      setOrderedSlides(slides)
    }
  }, [])
  const onResize = useCallback(() => {
    const ourPlacesWrapper = ref?.current
    const buttonWrapper = buttonWrapperRef?.current
    if (ourPlacesWrapper && buttonWrapper) {
      if (ourPlacesWrapper.scrollWidth > ourPlacesWrapper.clientWidth) {
        ourPlacesWrapper.classList.remove("no-scroll")
        buttonWrapper.classList.add("is-visible")
      } else {
        ourPlacesWrapper.classList.add("no-scroll")
        buttonWrapper.classList.remove("is-visible")
      }
    }
  }, [])

  const { ref } = useResizeDetector({
    handleHeight: false,
    refreshMode: "debounce",
    refreshRate: 200,
    onResize,
  })
  const SlideItems: Components = {
    ContentfulLocation: UrbanLocation,
    ContentfulNews: NewsCard,
  }

  useEffect(() => {
    onResize
  }, [])

  return (
    <StyledSection>
      <StyledHeader>{data?.heading}</StyledHeader>
      <PlacesWrapper className="slides-wrapper" ref={ref}>
        {orderedSlides?.map((slide, index: number) => {
          const SlideItem: React.ElementType = SlideItems[slide.__typename]

          return <SlideItem key={index} index={index} data={slide} />
        })}
      </PlacesWrapper>
      <StepButtonsWrapper
        ref={buttonWrapperRef}
        className="pagination-button-wrapper"
      >
        <StepButtonsButton
          className="go-back"
          onClick={() => scrollWrapperBy("back")}
        >
          <StyledIcon>
            <IconChevron />
          </StyledIcon>
        </StepButtonsButton>
        <StepButtonsButton
          className="go-forward"
          onClick={() => scrollWrapperBy("forward")}
        >
          <StyledIcon>
            <IconChevron />
          </StyledIcon>
        </StepButtonsButton>
      </StepButtonsWrapper>
    </StyledSection>
  )
}

const StyledSection = styled.div`
  ${CenteredMaxContentWidth};
  margin-top: 3rem;
  margin-bottom: 6rem;

  @media ${device.laptop} {
    margin-bottom: 4rem;
  }
`
const StyledHeader = styled.h2`
  display: flex;
  justify-content: center;
  margin-top: 6.37rem;
  margin-bottom: 2.5rem;

  @media ${device.laptop} {
    margin-top: 7rem;
    margin-bottom: 4rem;
  }
`

const PlacesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overflow-x: auto;
  width: calc(100% + (var(--site-main-gutter-mobile) * 2));
  margin-top: 0;
  margin-right: calc(var(--site-main-gutter-mobile) * -1);
  margin-bottom: 0;
  margin-left: calc(var(--site-main-gutter-mobile) * -1);
  padding: 1rem 0;
  scrollbar-color: transparent transparent;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &.no-scroll {
    justify-content: center;
  }

  &::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 0;
  }
  &::-webkit-scrollbar-thumb {
    background: transparent;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  @media ${device.tabletM} {
    width: calc(100% + (var(--site-main-gutter-tablet) * 2));
    margin-right: calc(var(--site-main-gutter-tablet) * -1);
    margin-left: calc(var(--site-main-gutter-tablet) * -1);
  }

  @media ${device.laptop} {
    width: calc(100% + (var(--site-main-gutter-laptop) * 2));
    margin-right: calc(var(--site-main-gutter-laptop) * -1);
    margin-left: calc(var(--site-main-gutter-laptop) * -1);
  }

  @media ${device.desktop} {
    width: calc(100% + (var(--site-main-gutter-desktop) * 2));
    margin-right: calc(var(--site-main-gutter-desktop) * -1);
    margin-left: calc(var(--site-main-gutter-desktop) * -1);
  }
`
const StepButtonsWrapper = styled.div`
  ${StepButtonWrapper};
  display: none;

  &.is-visible {
    display: flex;
  }
`
const StepButtonsButton = styled.button`
  ${PillButton};
  ${StepButton};
`
const StyledIcon = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  .icon {
    display: block;
    fill: var(--ud-black);
  }
`
