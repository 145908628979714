import { graphql, useStaticQuery } from "gatsby"

export const useThemeDaysSv = () => {
  const contentfulEvents = useStaticQuery(graphql`
    {
      allContentfulEvent(
        filter: {
          themeDay: { eq: true }
          title: { ne: "DO NOT DELETE" }
          node_locale: { eq: "sv" }
        }
      ) {
        nodes {
          heading
          node_locale
          location
          description {
            description
          }
          textField {
            raw
          }
          eventMonth: eventDate(formatString: "MMM", locale: "sv")
          eventDay: eventDate(formatString: "DD")
          eventTime: eventDate(formatString: "HH:mm")
          eventImage {
            gatsbyImageData
            description
          }
        }
      }
    }
  `)
  return contentfulEvents
}
