import { useStaticQuery, graphql } from "gatsby"

type ContentfulLocationQueryResult = {
  allContentfulLocation: {
    edges: { node: { location: string; buttonOneLinksTo: string } }[]
  }
}

export const useGetHashData = (
  inputLocation?: string
): ContentfulLocationQueryResult => {
  const hashData = useStaticQuery<ContentfulLocationQueryResult>(graphql`
    query contentfulLocation {
      allContentfulLocation(filter: { buttonOneLinksTo: { regex: "/.+?/" } }) {
        edges {
          node {
            location
            buttonOneLinksTo
          }
        }
      }
    }
  `)
  if (inputLocation) {
    const filteredEdges = hashData.allContentfulLocation.edges.filter(
      (edge) => edge.node.location === inputLocation
    )

    return {
      allContentfulLocation: {
        edges: filteredEdges,
      },
    }
  }

  return hashData
}
