import styled, { css } from "styled-components"
import { device } from "../../../shared/Device"
import { PillButton } from "../../../shared/StyleMixins"

export const ValidationText = styled.p`
  color: tomato;
  font-weight: bold;
  font-size: 16px;
  margin-top: 2px;
  margin-left: 6px;
`

export const FormContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  @media ${device.tabletM} {
    width: 48%;
  }
`

export const StyledThankYouForm = styled.form`
  position: absolute;
  flex-direction: column;
  display: flex;
  justify-content: center;
  z-index: 100;
  height: 100%;
  width: 100%;
  max-width: 26rem;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 0;
`

type StyledFormProps = {
  opacity?: boolean
}

export const StyledForm = styled.form<StyledFormProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 26rem;
  opacity: ${(StyledFormProps) => (StyledFormProps.opacity ? "0.25" : "1")};
  margin: 0 auto;
`

export const ThankYouFormFieldset = styled.fieldset`
  position: absolute;
  bottom: 0px;
  width: 100%;
  border: 0 none;
  margin: 0 0 1rem 0;
  padding: 0;
`

export const FormFieldset = styled.fieldset`
  width: 100%;
  border: 0 none;
  margin: 0 0 1rem 0;
  padding: 0;
`

export const TwoColumns = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  ${FormFieldset} {
    width: 48%;
  }
`

export const InputWrapper = styled.div`
  position: relative;
  color: var(--ud-beige);

  svg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

export const CheckboxWrapper = styled.label`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  cursor: pointer;
`

export const InputLabel = styled.label`
  position: absolute;
  font-size: 0.625rem;
  line-height: 1.5;
  color: var(--ud-black);
  margin-bottom: -0.625rem;
  padding: 0.375rem 0.25rem 0 0.25rem;
  z-index: 1;

  @media ${device.mobile} {
    padding: 0.375rem 1rem 0 1rem;
  }
`

export const FormField = css`
  position: relative;
  appearance: none;
  width: 100%;
  color: var(--ud-black);
  font-family: var(--font-text);
  font-size: 1rem;
  line-height: 1.5;
  text-transform: none;
  background: none;
  border: 0 none;
  outline: none;
  margin: 0;
  padding: 0.625rem 0.25rem;
  min-height: 2.75rem;
  z-index: 1;

  @media ${device.mobile} {
    padding: 0.625rem 1rem;
  }

  &:invalid.is-touched {
    + svg {
      color: tomato;
    }
  }
`

export const InputText = styled.input`
  ${FormField};
`

export const InputEmail = styled.input`
  ${FormField};
`

export const InputPhone = styled.input`
  ${FormField};
`

export const InputSelect = styled.select`
  ${FormField};
  padding-right: 2.25rem;
  background: transparent url("icon__chevron.svg") no-repeat
    calc(100% - 0.625rem) 50%;
  background-size: 1.25rem auto;

  @media ${device.mobile} {
    padding-right: 2.25rem;
  }
`

export const InputDate = styled.input`
  position: relative;
  appearance: none;
  width: 100%;
  color: var(--ud-black);
  font-family: var(--font-text);
  font-size: 1rem;
  line-height: 1.5;
  text-transform: none;
  background: none;
  border: 0 none;
  outline: none;
  margin: 0;
  padding: 1rem 0.25rem;
  min-height: 2.75rem;
  z-index: 1;

  @media ${device.mobile} {
    padding: 1.375rem 1rem 0.625rem 1rem;
  }

  &:invalid.is-touched {
    + svg {
      color: tomato;
    }
  }

  color: rgb(117, 117, 117);

  &.date-entry {
    color: var(--ud-black);
  }
`

export const InputTime = styled.input`
  position: relative;
  appearance: none;
  width: 100%;
  color: var(--ud-black);
  font-family: var(--font-text);
  font-size: 1rem;
  line-height: 1.5;
  text-transform: none;
  background: none;
  border: 0 none;
  outline: none;
  margin: 0;
  padding: 1rem 0.25rem;
  min-height: 2.75rem;
  z-index: 1;

  @media ${device.mobile} {
    padding: 1.375rem 1rem 0.625rem 1rem;
  }

  &:invalid.is-touched {
    + svg {
      color: tomato;
    }
  }
`

export const InputTextarea = styled.textarea`
  ${FormField};
  min-height: 8.75rem;
  resize: none;
`

export const InputCheckbox = styled.input`
  flex: 0 0 1.75rem;
  appearance: none;
  margin: auto 0;
  margin-right: 0.75rem;
  margin-left: 0.1875rem;
  padding: 0;
  width: 1.75rem;
  height: 1.75rem;
  background: transparent;
  border: 1px solid var(--ud-black);
  border-radius: 0;
  outline: none;
  cursor: pointer;

  &:focus,
  &:hover {
    background: linear-gradient(var(--ud-beige), var(--ud-beige)) no-repeat
      0.375rem 0.375rem/0.875rem 0.875rem;
  }

  &:checked {
    background: linear-gradient(var(--ud-black), var(--ud-black)) no-repeat
      0.25rem 0.25rem/1.125rem 1.125rem;
  }
`

export const CheckboxLabel = styled.div`
  flex: 0 0 calc(100% - 2.5rem);
  font-size: 0.875rem;
  margin: auto 0;
`

export const SubmitButton = styled.input`
  ${PillButton};
  width: 100%;
`

export const ThankYouHeader = styled.h3`
  text-align: center;
  margin-bottom: 2px;
  margin-top: 25%;
`
export const ThankYouText = styled.p`
  text-align: center;
  margin-bottom: 55%;
`

export const VisuallyHiddenLabel = styled.label`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`
