import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { device } from "../shared/Device"
import UD_link from "../images/UD_Link.svg"
import { GatsbyImage } from "gatsby-plugin-image"
import { CardTypes } from "../sections"

type Props = {
  card: CardTypes
}

export const FlippingCard: React.FC<Props> = ({ card }) => {
  const [cardColor, setColor] = useState("--secondary-blue")

  useEffect(() => {
    switch (card.backgroundColor) {
      case "Blue":
        setColor("--secondary-blue")
        break
      case "Green":
        setColor("--secondary-green")
        break
      case "Pink":
        setColor("--secondary-pink")
        break
      case "Yellow":
        setColor("--secondary-yellow")
        break
      default:
        setColor("--secondary-blue")
    }
  }, [cardColor])

  return (
    <CardWrapperLink className="flip-card" to={card.linkTo}>
      <InnerCardWrapper className="flip-card-inner">
        <CardFrontWrapper className="flip-card-front">
          <GatsbyImage
            image={card?.cardImage?.gatsbyImageData}
            alt={""}
            objectFit={"cover"}
            className="flip-card-image"
          />
          <StyledLink to={card.linkTo}>
            <CardHeadingDiv cardColor={cardColor}>
              <CardHeading>{card.headingOnTheFront}</CardHeading>
              <StyledIcon>
                <UD_link />
              </StyledIcon>
            </CardHeadingDiv>
          </StyledLink>
        </CardFrontWrapper>
      </InnerCardWrapper>
    </CardWrapperLink>
  )
}

type StyledProps = {
  cardColor: string
}

const InnerCardWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
`

const CardFrontWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3.75rem;
  width: 100%;
  height: 100%;

  @media ${device.tablet} {
    padding: 4.375rem 2rem;
  }

  @media ${device.tabletM} {
    padding: 4.375rem 3.5rem;
  }

  @media ${device.laptopM} {
    padding: 4.375rem 2rem;
  }

  @media ${device.laptopL} {
    padding: 4.375rem 3.5rem;
  }

  .flip-card-image {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`

const CardHeadingDiv = styled.div<StyledProps>`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.8125rem;
  background: var(${(StyledProps) => StyledProps.cardColor});
  padding: 1rem;
  transition: all 0.3s ease;

  @media ${device.tablet} {
    padding: 1rem 0.625rem;
  }

  @media ${device.laptop} {
    padding: 1rem;
  }

  @media ${device.laptopM} {
    padding: 1rem 0.625rem;
  }

  @media ${device.laptopL} {
    padding: 1rem;
  }
`

const StyledLink = styled(Link)``

const CardWrapperLink = styled(Link)`
  width: 100%;
  height: 11.25rem;
  overflow: hidden;
  color: var(--ud-black);

  @media ${device.tablet} {
    height: 12.5rem;
  }

  &:focus,
  &:hover,
  &:active {
    ${CardHeadingDiv} {
      background: var(--ud-yellow);
    }
  }
`

const CardHeading = styled.h4`
  font-size: 1.5rem;
  white-space: nowrap;
  margin: 0;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
`

const StyledIcon = styled.div``
