import React from "react"
import styled from "styled-components"
import ReactMarkdown from "react-markdown"
import { CenteredMaxContentWidth, PillButton } from "../shared/StyleMixins"
import { device } from "../shared/Device"
import L from "leaflet"
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet"
import { LocationNode } from "../hooks/useMapLocation"

type Props = {
  locations: LocationNode[]
}

const Map: React.FC<Props> = ({ locations }) => {
  function getIcon(iconUrl: string, iconWidth: number, iconHeight: number) {
    return L.icon({
      iconUrl: iconUrl,
      iconSize: [iconWidth, iconHeight],
      iconAnchor: [iconWidth / 2, iconHeight],
      popupAnchor: [0, -iconHeight],
    })
  }

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
  }

  if (typeof window !== "undefined") {
    return (
      <SectionWrapper>
        <MapSection>
          <MapContainer
            zoomControl={true}
            attributionControl={false}
            center={[59.327485332390026, 18.098329575117997]}
            zoom={12}
            scrollWheelZoom={false}
          >
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            {locations.map((location, index: number) => (
              <React.Fragment key={index}>
                <Marker
                  riseOnHover={true}
                  position={[
                    location.coordinates.lat,
                    location.coordinates.lon,
                  ]}
                  icon={getIcon(
                    location?.markerIcon?.file.url
                      ? location?.markerIcon?.file.url
                      : "./map-markers/map-marker__urban-deli__green.svg",
                    110,
                    110
                  )}
                >
                  {location.mapPopupTitle && (
                    <Popup
                      keepInView={true}
                      maxWidth={704}
                      closeButton={false}
                      position={[
                        location.coordinates.lat,
                        location.coordinates.lon,
                      ]}
                    >
                      <PopupContent>
                        <h3>{location.mapPopupTitle}</h3>
                        {location.mapPopupDescription &&
                          location.mapPopupDescription.mapPopupDescription && (
                            <ReactMarkdown
                              children={
                                location.mapPopupDescription.mapPopupDescription
                              }
                            />
                          )}
                        {location.buttonTextOne &&
                          location.buttonOneLinksTo && (
                            <p>
                              <StyledButton
                                data-hash={location.buttonOneLinksTo}
                                className="waiteraid-widget"
                                onClick={handleClick}
                              >
                                {location.buttonTextOne}
                              </StyledButton>
                            </p>
                          )}
                      </PopupContent>
                    </Popup>
                  )}
                </Marker>
              </React.Fragment>
            ))}
          </MapContainer>
        </MapSection>
      </SectionWrapper>
    )
  }
  return null
}

export default Map

const SectionWrapper = styled.div`
  ${CenteredMaxContentWidth};
  scroll-margin-top: 7.5rem;
`
const MapSection = styled.div`
  width: 100%;
  height: 65vh;
  overflow: hidden;

  @media ${device.tabletM} {
    height: 40rem;
  }

  .leaflet-container {
    width: 100%;
    height: 100%;
    z-index: 1;

    a {
      color: var(--ud-black);
    }

    .leaflet-pane .leaflet-tile-pane {
      filter: opacity(0.6) grayscale(0.8);
    }

    .leaflet-popup-content-wrapper,
    .leaflet-popup-tip {
      background: transparent;
      border-radius: 0;
      margin: 0;
      padding: 0;
    }
    .leaflet-popup-content {
      margin: 0;
      padding: 0;
    }
    .leaflet-popup-tip-container {
      display: none;
    }
    a.leaflet-popup-close-button {
      color: var(--ud-black);
      background: transparent;
    }
  }
`
const PopupContent = styled.div`
  display: flex;
  flex-direction: column;
  background: var(--ud-white);
  font-family: var(--font-text);
  color: var(--ud-black);
  padding: 1rem;
  width: 18.75rem;

  @media ${device.tabletM} {
    width: 36rem;
  }

  @media ${device.laptopM} {
    width: 44rem;
  }

  h3 {
    font-size: 2.25rem;
    line-height: 1.11111111;
    margin: 0 0 1rem 0;
  }

  p {
    align-self: flex-start;
    font-size: 1.125rem;
    line-height: 1.22222222;
    margin: 0 0 1rem 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
`

const StyledButton = styled.button`
  ${PillButton};
  margin-top: 1rem;
`
