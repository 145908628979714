import { Link } from "gatsby"
import React from "react"
import styled, { css } from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { ILocation } from "../sections/Slider"
import { PillButton } from "../shared/StyleMixins"
import { device } from "../shared/Device"
import getLocationColor from "../utils/getLocationColor"

type Props = { data: ILocation }

const UrbanLocation: React.FC<Props> = ({ data }) => {
  const { locationImage, heading } = data
  const buttons = {
    buttonText: data?.buttonTextOne,
    buttonLink: data?.buttonOneLinksTo,
    button2Text: data?.buttonTextTwo,
    button2Link: data?.buttonTwoLinksTo,
  }

  const locationbordercolor = getLocationColor(heading.toLocaleLowerCase())
  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
  }

  return (
    <StyledLink
      to={buttons.button2Link ? buttons.button2Link : "/"}
      className="our-place slide-wrapper"
      locationbordercolor={locationbordercolor}
    >
      <ImageDiv>
        <GatsbyImage
          image={locationImage?.gatsbyImageData}
          alt={locationImage?.description || "missing"}
          loading={"eager"}
          objectFit={"cover"}
          className="PlacesImageWrapper"
        />
      </ImageDiv>
      <LocationInfo>
        <LocationHeading>{heading}</LocationHeading>
        <LinkButtons>
          {buttons.buttonText && buttons.buttonLink && (
            <StyledButton
              data-hash={buttons.buttonLink}
              className="waiteraid-widget"
              onClick={handleClick}
            >
              {buttons.buttonText}
            </StyledButton>
          )}
          {buttons.button2Text && buttons.button2Link && (
            <StyledButton color={"white"}>{buttons.button2Text}</StyledButton>
          )}
        </LinkButtons>
      </LocationInfo>
    </StyledLink>
  )
}

type StyledButtonProps = {
  color?: string
}

type StyledLinkProps = {
  locationbordercolor?: string
}

const StyledLink = styled(Link)<StyledLinkProps>`
  --block-border-color: var(
    ${(StyledLinkProps) => StyledLinkProps.locationbordercolor}
  );
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 0;
  width: 58%;
  margin-right: 0.875rem;
  background: var(--ud-white);
  border-bottom: 0.9375rem solid var(--block-border-color);
  color: var(--ud-black);
  text-decoration: none;
  transition: transform 0.3s ease-in-out;

  @media ${device.tabletM} {
    width: 38%;
  }

  @media ${device.laptopM} {
    width: 28%;
  }

  &:nth-child(5n + 1) {
    /* --block-border-color: var(--ud-green); */
    &:focus,
    &:focus-within,
    &:hover {
      transform: rotate(-1deg);
    }
  }
  &:nth-child(5n + 2) {
    transform: rotate(-1deg);

    &:focus,
    &:focus-within,
    &:hover {
      transform: rotate(0deg);
    }
  }
  &:nth-child(5n + 3) {
    /* --block-border-color: var(--ud-blue); */
    transform: rotate(2deg);

    &:focus,
    &:focus-within,
    &:hover {
      transform: rotate(1deg);
    }
  }
  &:nth-child(5n + 4) {
    /* --block-border-color: var(--ud-orange); */

    &:focus,
    &:focus-within,
    &:hover {
      transform: rotate(-1deg);
    }
  }
  &:nth-child(5n + 5) {
    /* --block-border-color: var(--ud-bronze); */
    transform: rotate(1deg);

    &:focus,
    &:focus-within,
    &:hover {
      transform: rotate(0deg);
    }
  }
  &:nth-child(5n + 6) {
    /* --block-border-color: var(--secondary-pink); */
    transform: rotate(-2deg);

    &:focus,
    &:focus-within,
    &:hover {
      transform: rotate(-1deg);
    }
  }
`
const ImageDiv = styled.div`
  width: 100%;
  aspect-ratio: 1.49 / 1;

  .PlacesImageWrapper {
    width: 100%;
    height: 100%;
  }
`

const LocationInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.25rem;
`
const LocationHeading = styled.h3`
  margin-bottom: 0.125rem;
`
const LinkButtons = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.mobileL} {
    flex-direction: row;
    flex-wrap: wrap;
  }
`
const StyledButton = styled.button<StyledButtonProps>`
  ${PillButton};
  margin-top: 0.875rem;
  --button-border-width: 1px;
  ${(Props) =>
    Props.color == "white"
      ? css`
          --button-background-color: var(--ud-white);
          --button-border-color: var(--ud-black);
        `
      : css`
          --button-background-color: var(--ud-yellow);
        `}

  @media ${device.mobileL} {
    margin-right: 0.875rem;

    &:last-child {
      margin-right: 0;
    }
  }

  &:focus,
  &:hover {
    --button-background-color: var(--ud-blue);
    --button-border-color: var(--ud-blue);
    --button-text-color: var(--ud-white);
  }
`

export default UrbanLocation
