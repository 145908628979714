import React from "react"
import styled from "styled-components"
import { device } from "../shared/Device"
import { QuestionAndAnswer } from "../components/QuestionAndAnswer"
import Sticker from "../components/Sticker"
import { CenteredMaxContentWidth } from "../shared/StyleMixins"

export type qna = {
  qna: {
    question: string
    answer: { answer: string }
  }
}

type QnaTypes = {
  question: string
  answer: { answer: string }
}

type Props = {
  data: {
    heading: string
    sticker: string
    questions: QnaTypes[]
  }
}

export const QandA: React.FC<Props> = ({ data }) => {
  const { heading, sticker, questions } = data

  return (
    <SectionWrapper
      className={sticker && sticker !== "No sticker" ? "has-sticker" : ""}
    >
      <SectionContent>
        <Heading>{heading}</Heading>
        <QnaWrapper>
          {questions.map((qna, index) => {
            return (
              <>
                <QuestionAndAnswer qna={qna} key={index} />
              </>
            )
          })}
        </QnaWrapper>
        {sticker && sticker !== "No sticker" ? (
          <StickerWrapper>
            <Sticker sticker={sticker.toLowerCase()} />
          </StickerWrapper>
        ) : null}
      </SectionContent>
    </SectionWrapper>
  )
}

const SectionContent = styled.div`
  ${CenteredMaxContentWidth};
  max-width: var(--megamenu-max-width, 80rem);
  position: relative;
  padding: 0 0;
  margin-bottom: 15rem;
`

const SectionWrapper = styled.div`
  margin-top: 13.75rem;

  @supports (margin-top: clamp(1rem, 2rem, 3rem)) {
    margin-top: clamp(6.875rem, 5.2885rem + 7.0513vw, 13.75rem);
  }

  &.has-sticker {
    margin-top: 14rem;

    @supports (margin-top: clamp(1rem, 2rem, 3rem)) {
      margin-top: clamp(11rem, 10.30769rem + 3.0769vw, 14rem);
    }

    ${SectionContent} {
      padding: 6rem 0 3rem;

      @media ${device.laptop} {
        padding: 7.5rem 0 3rem;
      }
    }
  }
`

const Heading = styled.h2`
  display: flex;
  justify-content: center;
`

const QnaWrapper = styled.ol`
  width: 100%;
  max-width: 40.625rem;
  margin: 0 auto;
  padding: 0;
  list-style: none;
`

const StickerWrapper = styled.div`
  position: absolute;
  width: 12.5rem;
  height: 12.5rem;
  top: -8.125rem;
  left: 0;
  transform: rotate(10deg);
  z-index: 99;
  pointer-events: none;

  @media ${device.laptop} {
    width: 17rem;
    height: 17rem;
    top: -11rem;
  }
`
