import React, { useState, useEffect, useRef, useContext } from "react"
import styled, { css } from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import ReactMarkdown from "react-markdown"
import Sticker from "../components/Sticker"
import { CenteredMaxContentWidth, PillButton } from "../shared/StyleMixins"
import { ContentfulImage, GatsbyImageType } from "../shared/ContentfulTypes"
import { device } from "../shared/Device"
import { Link } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import { useGetHashData } from "../hooks/useGetHashData"
import { IModalContext, ModalContext } from "../context/modalContext"
import { scrollToSection } from "../utils/createAnchorLink"

const richTextOptions = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
      const url = node?.data?.target?.file?.url
      const name = node?.data?.target?.file?.fileName
      return (
        <a href={url} target="_blank">
          {name}
        </a>
      )
    },

    [INLINES.HYPERLINK]: (node: any) => {
      return (
        <a href={node?.data?.uri} target="_blank">
          {node.content[0].value}
        </a>
      )
    },
    [INLINES.ASSET_HYPERLINK]: (node: any) => {
      const url = node?.data?.target?.file?.url
      const name = node?.content[0].value

      return (
        <a href={url} target="_blank">
          {name}
        </a>
      )
    },
  },
}

type PromotionBlock = {
  kicker: string
  heading: string
  id: string
  description: { description: string }
  sticker2?: string
  image: ContentfulImage
  layout: "Wide" | "Contained" | "Side by side"
  button: string
  buttonLinkTo?: string
  textField: any
  bookATable?: boolean
  bookTableLocation?: string
  bookATableButtonText?: string
  numberOfButtons?: number
}

type Props = {
  data: PromotionBlock
}

export const PromoteCTA: React.FC<Props> = ({ data }) => {
  let {
    layout,
    heading,
    kicker,
    description,
    image,
    sticker2,
    button,
    buttonLinkTo,
    textField,
    bookATable,
    bookTableLocation,
    bookATableButtonText,
    numberOfButtons,
  } = data
  const fullSize = layout === "Wide" ? true : false
  const [linkTo, setLinkTo] = useState(buttonLinkTo || "/")
  const [mailLink, setMailLink] = useState(false)
  const tableLocation = bookTableLocation || "all"
  const context = useContext(ModalContext)

  numberOfButtons = numberOfButtons === null ? 1 : numberOfButtons
  bookATableButtonText =
    bookATableButtonText === null ? "" : bookATableButtonText

  //Temporary code for chrismas promo 2023
  //creates a anchor for the menu section
  const sectionRef = useRef<HTMLDivElement>(null)
  let sectionsID = ""

  if (heading === "Julmiddag på Sveavägen") {
    sectionsID = "julmiddag"
  } else if (heading === "Jul hos dig ") {
    sectionsID = "bestall-julmat"
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (window.location.hash === `#${sectionsID}` && sectionRef.current) {
        const y =
          sectionRef.current.getBoundingClientRect().top + window.scrollY - 100
        window.scrollTo({ top: y, behavior: "smooth" })
      }
    }, 1000)

    return () => clearTimeout(timer)
  }, [])

  //
  // End of temporary code.

  const getLinkType = (link: string) => {
    if (link.includes("mailto:")) return { mailLink: true, linkTo: link }
    if (link.includes("http")) return { mailLink: false, linkTo: link }
    return { mailLink: false, linkTo: `/${link}` }
  }

  useEffect(() => {
    if (button && buttonLinkTo) {
      const { mailLink, linkTo } = getLinkType(buttonLinkTo)
      setMailLink(mailLink)
      setLinkTo(linkTo)
    }
  }, [buttonLinkTo])

  const renderButtonLink = (
    button: string,
    linkTo: string,
    mailLink: boolean,
    bookATable?: boolean
  ) => {
    const linkBtns = []
    const buttonTexts = new Map()

    if ((!button || !linkTo) && !bookATable) return null

    if (mailLink && (numberOfButtons === 1 || numberOfButtons === 2)) {
      linkBtns.push(
        <StyledButton numberOfButtons={numberOfButtons} key="mailLink">
          <a href={linkTo as string}>{button as string}</a>
        </StyledButton>
      )
    }

    if (
      linkTo &&
      !bookATable &&
      (numberOfButtons === 1 || numberOfButtons === 2)
    ) {
      linkBtns.push(
        <StyledButton numberOfButtons={numberOfButtons} key="mailLink">
          <a href={linkTo as string}>{button as string}</a>
        </StyledButton>
      )
    }

    if (bookATable && (numberOfButtons === 1 || numberOfButtons === 2)) {
      const selectedLocationObj = useGetHashData(tableLocation)
      let hashNr

      if (selectedLocationObj) {
        hashNr =
          selectedLocationObj?.allContentfulLocation?.edges[0]?.node
            ?.buttonOneLinksTo
      }

      const tableButtonText = bookATableButtonText || "Boka bord"

      linkBtns.push(
        <StyledLink
          numberOfButtons={numberOfButtons}
          key="bookATable"
          to={"/"}
          {...(hashNr
            ? { "data-hash": hashNr }
            : { onClick: () => context?.toggleModal() })}
          className="waiteraid-widget"
        >
          {tableButtonText}
        </StyledLink>
      )
    }

    linkBtns.forEach((btn) => {
      const buttonText = btn.props?.children?.props?.children

      if (!buttonTexts.has(buttonText)) {
        buttonTexts.set(buttonText, btn)
      }
    })

    const filteredButtons = Array.from(buttonTexts.values())

    return filteredButtons
  }

  const renderSticker = (sticker2: any, fullSize: boolean) => {
    if (!sticker2 || sticker2 === "No sticker") return null
    return (
      <StickerWrapper className={fullSize ? "in-full-size-parent" : ""}>
        <Sticker sticker={sticker2.toLowerCase()} />
      </StickerWrapper>
    )
  }

  const hasSticker = sticker2 && sticker2 !== "No sticker"

  return (
    <StyledSection
      layout={layout}
      fullSize={fullSize}
      className={`${hasSticker ? "has-sticker" : ""} ${
        fullSize ? "full-size" : ""
      }`}
    >
      <Wrapper
        ref={sectionRef}
        id={sectionsID}
        fullSize={fullSize}
        layout={layout}
      >
        <ImageWrapper fullSize={fullSize} layout={layout}>
          <GatsbyImage
            image={image?.gatsbyImageData}
            alt={image?.description || ""}
            loading={"lazy"}
            objectFit={"cover"}
            className="promote-cta-image-wrapper"
          />
        </ImageWrapper>

        <ContentWrapper fullSize={fullSize} layout={layout}>
          <Title>{kicker}</Title>
          <Heading>{heading}</Heading>
          <Description>
            {renderRichText(textField, richTextOptions)}
          </Description>
          <ButtonWrapper>
            {renderButtonLink(button, linkTo, mailLink, bookATable)}
          </ButtonWrapper>
        </ContentWrapper>
      </Wrapper>
      {renderSticker(sticker2, fullSize)}
    </StyledSection>
  )
}

type StyledProps = {
  layout?: string
  fullSize?: boolean
  numberOfButtons?: number
}

const StyledSection = styled.div<StyledProps>`
  height: 100%;
  position: relative;
  ${CenteredMaxContentWidth};
  margin-top: 6.5rem;
  margin-bottom: 4.5rem;
  ${(StyledProps) =>
    StyledProps.layout === "Wide"
      ? css`
          max-width: var(--big-content-max-width);
        `
      : StyledProps.layout === "Contained"
      ? css``
      : css``}

  @media ${device.tablet} {
  }

  @media ${device.tabletM} {
    height: ${(StyledProps) =>
      StyledProps.layout === "Contained" ? "100%" : ""};
    /* height: 28rem; */
  }

  @media ${device.laptop} {
    margin-top: 8rem;
    margin-bottom: 8rem;
    ${(StyledProps) =>
      StyledProps.layout === "Side by side"
        ? css`
            height: 28rem;
          `
        : css``}
    &:last-child {
      margin-bottom: 4rem;
    }
  }
  &.has-sticker {
    &:not(.full-size) {
      margin-top: 15rem;
      @supports (margin-top: clamp(1rem, 2rem, 3rem)) {
        margin-top: clamp(
          13rem,
          12.538461538461538rem + 2.051282051282051vw,
          15rem
        );
      }
    }
    &.full-size {
      margin-bottom: 15rem;
      @supports (margin-bottom: clamp(1rem, 2rem, 3rem)) {
        margin-bottom: clamp(
          13rem,
          12.538461538461538rem + 2.051282051282051vw,
          15rem
        );
      }
    }
  }
`

const Wrapper = styled.div<StyledProps>`
  ${(StyledProps) =>
    StyledProps.layout === "Wide"
      ? css`
          display: grid;
          grid-template-columns: 1fr;
          grid-template-rows: 9em 1fr 9em;
          gap: 0;
          width: calc(100% + (var(--site-main-gutter-mobile) * 2));
          margin: 0 calc(var(--site-main-gutter-mobile) * -1);
        `
      : StyledProps.layout === "Contained"
      ? css`
          height: 100%;
          display: flex;
          flex-direction: column;
        `
      : css`
          display: grid;
          grid-template-columns: 1fr;
          grid-template-rows: 1fr;
        `}
  @media ${device.tabletM} {
    ${(StyledProps) =>
      StyledProps.layout === "Wide"
        ? css`
            width: calc(100% + (var(--site-main-gutter-tablet) * 2));
            margin: 0 calc(var(--site-main-gutter-tablet) * -1);
          `
        : StyledProps.layout === "Side by side"
        ? css`
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: 1fr;
            margin-bottom: 10rem;
          `
        : css``}
  }
  @media ${device.laptop} {
    /* height: 27.5rem; */

    ${(StyledProps) =>
      StyledProps.layout === "Wide"
        ? css`
            width: calc(100% + (var(--site-main-gutter-laptop) * 2));
            margin: 0 calc(var(--site-main-gutter-laptop) * -1);
          `
        : StyledProps.layout === "Contained"
        ? css`
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-template-rows: 0.5fr 1fr 0.3fr;
            grid-column-gap: 0;
            grid-row-gap: 0;
          `
        : css`
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: 1fr;
            margin-bottom: 13.5rem;
          `}
  }
  @media ${device.desktopL} {
    ${(StyledProps) =>
      StyledProps.layout === "Wide"
        ? css`
            width: 100%;
            margin: 0 auto;
          `
        : css``}
  }
`

const ImageWrapper = styled.div<StyledProps>`
  height: 27rem;
  ${(StyledProps) =>
    StyledProps.layout === "Wide"
      ? css`
          grid-area: 1 / 1 / 4 / 2;
        `
      : StyledProps.layout === "Contained"
      ? css`
          max-width: 60.8125rem;
          margin-left: auto;
          transform: translateX(15%) rotate(2deg);
        `
      : css``}
  @media ${device.laptop} {
    ${(StyledProps) =>
      StyledProps.layout === "Wide"
        ? css``
        : StyledProps.layout === "Contained"
        ? css`
            grid-area: 1 / 2 / 3 / 5;
            z-index: 1;
            transform: rotate(2deg);
          `
        : css``}
  }
  .promote-cta-image-wrapper {
    ${(StyledProps) =>
      StyledProps.layout === "Wide"
        ? css`
            width: 100%;
            height: 100%;
          `
        : StyledProps.layout === "Contained"
        ? css`
            width: 100%;
            height: 0;
            padding-bottom: 48.26388889%;
          `
        : css`
            height: 100%;
          `}
    @supports (aspect-ratio: 2.07194245 / 1) {
      ${(StyledProps) =>
        StyledProps.layout === "Wide"
          ? css``
          : StyledProps.layout === "Contained"
          ? css`
              height: auto;
              padding-bottom: 0;
              aspect-ratio: 2.07194245 / 1;
            `
          : StyledProps.layout === "Side by side"
          ? css``
          : css``}
    }
  }
`

const ContentWrapper = styled.div<StyledProps>`
  height: 27rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--ud-white);
  padding: 2rem;
  z-index: 2;

  ${(StyledProps) =>
    StyledProps.layout === "Wide"
      ? css`
          grid-area: 2 / 1 / 3 / 2;
          margin: 0 var(--site-main-gutter-mobile);
        `
      : StyledProps.layout === "Contained"
      ? css`
          margin-top: -1rem;
        `
      : css``}
  @media ${device.tablet} {
    ${(StyledProps) =>
      StyledProps.layout === "Wide"
        ? css``
        : StyledProps.layout === "Contained"
        ? css`
            margin-top: -2.25rem;
          `
        : css``}
  }
  @media ${device.tabletM} {
    ${(StyledProps) =>
      StyledProps.layout === "Wide"
        ? css`
            max-width: 40rem;
            margin: 0 var(--site-main-gutter-tablet) 0 auto;
          `
        : StyledProps.layout === "Contained"
        ? css`
            margin-top: -4.5rem;
          `
        : css``}
  }
  @media ${device.laptop} {
    padding: 3.75rem;
    ${(StyledProps) =>
      StyledProps.layout === "Wide"
        ? css`
            margin: 0 var(--site-main-gutter-laptop) 0 auto;
          `
        : StyledProps.layout === "Contained"
        ? css`
            padding: 1rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            grid-area: 2 / 1 / 4 / 3;
            z-index: 2;
          `
        : css``}
  }
  @media ${device.laptopM} {
    ${(StyledProps) =>
      StyledProps.layout === "Wide"
        ? css`
            max-width: 48rem;
          `
        : css``}
  }
  @media ${device.desktop} {
    ${(StyledProps) =>
      StyledProps.layout === "Wide"
        ? css`
            margin: 0 calc((var(--vw) - var(--content-max-width)) / 2) 0 auto;
          `
        : css``}
  }
  @media ${device.desktopL} {
    ${(StyledProps) =>
      StyledProps.layout === "Wide"
        ? css`
            margin: 0
              calc(
                (var(--big-content-max-width) - var(--content-max-width)) / 2
              )
              0 auto;
          `
        : css``}
  }
`

const Title = styled.h3`
  font-size: 1.5rem;
  line-height: 1.16666667;
  margin: 0 0 2rem 0;
`

const Heading = styled.h4`
  font-size: 2rem;
  line-height: 1.125;
  margin: 0 0 1rem 0;
  @media ${device.laptop} {
    font-size: 2.375rem;
    line-height: 1.111111111;
  }
`

const Description = styled.section`
  font-size: 1.125rem;
  line-height: 1.22222222;
  margin: 0 0 2rem 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
  text-overflow: ellipsis;
  a {
    color: var(--hyperlink-blue);
  }
`

const ButtonWrapper = styled.div`
  display: inherit;
`

const StyledLink = styled(Link)<StyledProps>`
  ${PillButton};
  align-self: flex-start;
  margin-right: 0.5rem;
`

const StickerWrapper = styled.div`
  position: absolute;
  width: 10.5rem;
  height: 10.5rem;
  transform: rotate(3deg);
  z-index: 99;
  pointer-events: none;
  &:not(.in-full-size-parent) {
    top: -11rem;
    left: -1.5rem;
    @media ${device.tablet} {
      left: 10%;
    }
    @media ${device.laptop} {
      width: 17rem;
      height: 17rem;
      top: -11rem;
      left: 17%;
    }
    @media ${device.laptopM} {
      left: 20%;
    }
    @media ${device.laptopL} {
      left: 26%;
    }
  }
  &.in-full-size-parent {
    bottom: -5rem;
    left: 1.5rem;
    @media ${device.laptop} {
      width: 17rem;
      height: 17rem;
      bottom: -7rem;
      left: 0;
    }
    @media ${device.desktopL} {
      left: 3.125rem;
    }
  }
`

const StyledButton = styled.button<StyledProps>`
  ${PillButton};
  align-self: flex-start;
  margin-right: 0.5rem;
`
