import React, { useContext } from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { Occasion } from "../sections/Calendar"
import { PillButton } from "../shared/StyleMixins"
import { device } from "../shared/Device"
import { Link } from "gatsby"
import getLocationColor from "../utils/getLocationColor"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import { ILocaleContext, LocaleContext } from "../context/localeContext"

const richTextOptions = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
      return null
    },
    [INLINES.HYPERLINK]: (node: any) => {
      return (
        <a href={node?.data?.uri} target="_blank">
          {node.content[0].value}
        </a>
      )
    },
  },
}

type EventCardProps = {
  event: Occasion
  index: number
}

const EventCard: React.FC<EventCardProps> = ({ event, index }) => {
  const {
    textField,
    heading,
    fields: { newSlug },
  } = event

  const { locale } = useContext(LocaleContext) as ILocaleContext
  const linksTo = locale === "en" ? `/${newSlug}` : newSlug
  const locationColor = getLocationColor(event?.location?.toLowerCase())

  return (
    <React.Fragment key={index}>
      <CardWrapper>
        <EventImage>
          <GatsbyImage
            image={event?.eventImage?.gatsbyImageData}
            alt={event?.eventImage?.description || ""}
            className="event-image-wrapper"
          />
          <Date>
            <Month>{event.eventMonth}</Month>
            <Day>{event.eventDay}</Day>
          </Date>
        </EventImage>
        <EventInfo locationColor={locationColor}>
          <Details>
            <LocationTime>
              <Location>{event.location}</Location>
              <Time>{`${event.eventTime}${
                event.eventEndTime ? ` - ${event.eventEndTime}` : ""
              }`}</Time>
            </LocationTime>
            <Heading>{heading}</Heading>
            <Description>
              {renderRichText(textField, richTextOptions)}
            </Description>
            <StyledLink to={linksTo}>
              <ReadMore>{locale === "en" ? "READ MORE" : "LÄS MER"}</ReadMore>
            </StyledLink>
          </Details>
        </EventInfo>
      </CardWrapper>
    </React.Fragment>
  )
}
type StyledProps = {
  locationColor: string
}

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 3.5rem;

  @media ${device.tablet} {
    flex-direction: row;
    margin-bottom: 1.25rem;
  }

  @media ${device.laptop} {
    flex-direction: column;
    margin-bottom: 3.5rem;
  }

  @media ${device.laptopM} {
    flex-direction: row;
    margin-bottom: 1.25rem;
  }
`
const EventImage = styled.div`
  position: relative;
  flex: 1 0 auto;
  width: 100%;

  @media ${device.tablet} {
    width: 42%;
  }

  @media ${device.laptop} {
    width: 100%;
  }

  @media ${device.laptopM} {
    width: 42%;
  }

  .event-image-wrapper {
    height: 100%;
  }
`
const Date = styled.div`
  position: absolute;
  top: 0;
  left: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--ud-light);
  width: 4.5rem;
  padding: 1.25rem 1rem 1rem 1rem;
  z-index: 2;
`
const Month = styled.span`
  font-family: var(--font-heading);
  font-size: 1.5rem;
  line-height: 1;
  font-weight: 500;
  text-transform: uppercase;
  margin: 0 0 0.25rem 0;
`
const Day = styled.span`
  font-family: var(--font-heading);
  font-size: 2rem;
  line-height: 1;
  font-weight: 500;
`
const EventInfo = styled.div<StyledProps>`
  background-color: var(--ud-white);
  border-top: 0.9375rem solid var(${({ locationColor }) => locationColor});
  width: 100%;

  @media ${device.tablet} {
    width: 58%;
  }

  @media ${device.laptop} {
    width: 100%;
  }

  @media ${device.laptopM} {
    width: 58%;
  }
`
const Details = styled.div`
  padding: 0.5rem 0.625rem 0 0.625rem;

  @media ${device.tablet} {
    padding: 1rem 1.25rem 0 1.25rem;
  }

  @media ${device.laptop} {
    padding: 0.5rem 0.625rem 0 0.625rem;
  }

  @media ${device.laptopM} {
    padding: 1rem 1.25rem 0 1.25rem;
  }
`
const LocationTime = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: nowrap;
  margin: 0 0 0.625rem 0;
`
const Location = styled.h4`
  flex: 0 0 auto;
  font-size: 1.5rem;
  line-height: 1.16666667;
  margin: 0;
  padding: 0 1rem 0 0;

  @media ${device.tablet} {
    font-size: 1.25rem;
    line-height: 1.2;
  }

  @media ${device.tabletM} {
    font-size: 1.5rem;
    line-height: 1.16666667;
  }
`
const Time = styled.span`
  flex: 0 0 auto;
  font-size: 1.125rem;
  line-height: 1.55555556;
  white-space: nowrap;
  overflow: hidden;

  @media ${device.tablet} {
    font-size: 1rem;
    line-height: 1.5;
  }

  @media ${device.tabletM} {
    font-size: 1.125rem;
    line-height: 1.55555556;
  }
`
const Heading = styled.h5`
  font-size: 2.375rem;
  line-height: 1.125;
  margin: 0 0 1.5rem 0;
`
const Description = styled.section`
  width: 100%;
  margin: 0 0 1.25rem 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis;

  @media ${device.mobileS} {
    min-height: 0;
  }

  @media ${device.laptop} {
    min-height: 5.62rem;
  }

  p {
    display: inline;
  }
`
const ReadMore = styled.button`
  ${PillButton};
  --button-background-color: transparent;
  --button-border-width: 0;
  padding: 0;
  text-decoration: underline;

  &:focus,
  &:active,
  &:hover {
    --button-background-color: transparent;
    --button-text-color: var(--ud-black);
    opacity: 0.5;
  }
`

const StyledLink = styled(Link)`
  color: inherit;
`

export default EventCard
