import React from "react"
import styled from "styled-components"
import { CenteredMaxContentWidth } from "../shared/StyleMixins"
import { device } from "../shared/Device"

import { CateringForm } from "../components/ContactUsForms/CateringForm"
import { ContactUsForm } from "../components/ContactUsForms/ContactUsForm"

type FormProps = {
  data: {
    heading: string
    subHeading?: string
    formType:
      | "ContactUs"
      | "ContactUsPreselectedMatterUrbanDeliPaVag"
      | "Catering"
      | "CateringPreselectedMatterSubscription"
  }
}

export const ContactUs: React.FC<FormProps> = ({ data }) => {
  const { heading, subHeading, formType } = data

  const formMapper = {
    ContactUs: <ContactUsForm />,
    ContactUsPreselectedMatterUrbanDeliPaVag: (
      <ContactUsForm matter="urbanDeliPaVag" />
    ),
    Catering: <CateringForm />,
    CateringPreselectedMatterSubscription: (
      <CateringForm matter="subscription" />
    ),
  }

  return (
    <SectionWrapper id={"contactus"}>
      <TextContainer>
        <Heading>{heading}</Heading>
        {subHeading && <SubInfo>{subHeading}</SubInfo>}
      </TextContainer>
      {formType ? formMapper[formType] : formMapper["ContactUs"]}
    </SectionWrapper>
  )
}

const SectionWrapper = styled.div`
  ${CenteredMaxContentWidth};
  display: flex;
  flex-direction: column;
  max-width: 68.75rem;
  margin: 0 auto 3.5rem auto;
  scroll-margin-top: 7.5rem;

  @media ${device.tabletM} {
    flex-direction: row;
    justify-content: space-between;
    padding-top: 0.8125rem;
  }
`

const TextContainer = styled.div`
  margin: 0 auto;
  margin-bottom: 3.125rem;
  max-width: 26rem;

  @media ${device.tabletM} {
    margin: 0;
    width: 48%;
    max-width: none;
  }
`

const Heading = styled.h2`
  margin: 0 0 1rem 0;
  text-align: left;

  @media ${device.tabletM} {
    margin: -0.8125rem 0 1.375rem 0;
    text-align: left;
  }

  @media ${device.laptop} {
    margin: -0.8125rem 0 2.125rem 0;
  }
`

const SubInfo = styled.p``
