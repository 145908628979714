import { Link } from "gatsby"
import React, { useState, useEffect, useContext } from "react"
import styled from "styled-components"
import { ContentfulLocation } from "../shared/ContentfulTypes"
import { device } from "../shared/Device"
import { CenteredMaxContentWidth, SquareButton } from "../shared/StyleMixins"
import { seperateRichText } from "../utils/seperateRichText"
import { useLocations } from "../hooks/useLocation"
import * as translations from "../utils/translations"

type LocationPresentationProps = {
  data: ContentfulLocation
}

export const LocationPresentation: React.FC<LocationPresentationProps> = ({
  data,
}) => {
  const { heading, description, otherInformation, location } = data
  const [showButtons, setShowButtons] = useState(true)
  const [currentLocale, setCurrentLocale] = useState("")

  const locationWithoutButtons = [
    "Urban Deli på väg",
    "Urban Deli On The Road",
    "Vad är på gång",
  ]

  useEffect(() => {
    if (typeof window !== undefined) {
      window.location.href.includes("/en/")
        ? setCurrentLocale("en")
        : setCurrentLocale("sv")
    }
  }, [currentLocale])

  useEffect(() => {
    locationWithoutButtons.includes(location)
      ? setShowButtons(false)
      : setShowButtons(true)
  }, [showButtons])

  let seperatedRichText
  if (otherInformation) {
    seperatedRichText = seperateRichText(otherInformation)
  }

  return (
    <SectionWrapper>
      <SideContainer>
        <Heading>{heading}</Heading>
        {showButtons ? (
          <Buttons>
            <StyledLink className="" to={"#events"}>
              {currentLocale === "en"
                ? translations.menu.buttons.en.kalendarium
                : translations.menu.buttons.se.kalendarium}
            </StyledLink>
            <StyledLink className="" to={"#findus"}>
              {currentLocale === "en"
                ? translations.menu.buttons.en.hittahit
                : translations.menu.buttons.se.hittahit}
            </StyledLink>
            <StyledLink className="" to={"#findus"}>
              {currentLocale === "en"
                ? translations.menu.buttons.en.kontakt
                : translations.menu.buttons.se.kontakt}
            </StyledLink>
          </Buttons>
        ) : null}
      </SideContainer>
      <MainContainer>
        {description?.description ? (
          <PresentationWrapper>{description?.description}</PresentationWrapper>
        ) : null}
        <OtherInformation>
          {seperatedRichText?.map((column, i) => (
            <SectionOtherInformation key={i}>{column}</SectionOtherInformation>
          ))}
        </OtherInformation>
      </MainContainer>
    </SectionWrapper>
  )
}

const SectionWrapper = styled.div`
  ${CenteredMaxContentWidth};
  display: flex;
  flex-direction: column;
  margin: 3.3125rem auto 9.5rem auto;

  @media ${device.laptop} {
    flex-direction: row;
    justify-content: space-between;
    max-width: 68.75rem;
  }
`

const SideContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  @media ${device.laptop} {
    flex: 0 0 22rem;
    flex-direction: column;
    align-items: flex-start;
    width: 22rem;
    margin: -0.8125rem 2rem 0 0;
  }

  @media ${device.laptopM} {
    flex: 0 0 26rem;
    width: 26rem;
  }
`

const Heading = styled.h1`
  padding-right: var(--site-main-gutter-mobile);
  padding-left: var(--site-main-gutter-mobile);

  @media ${device.laptop} {
    padding-right: 0;
    padding-left: 0;
  }
`

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 21.25rem;
  white-space: nowrap;

  &:not(:empty) {
    margin: 0 auto 3rem auto;

    @media ${device.laptop} {
      margin: 0 auto 3rem;
      margin-right: 5.5rem;
    }
  }
`

const StyledLink = styled(Link)`
  ${SquareButton};
  --hover-rotate: 1deg;
  --button-color: var(--secondary-blue);
  background-color: var(--button-color);
  border-color: var(--button-color);

  margin-bottom: 1.4rem;
  width: 100%;
  transform: rotate(-2deg);
  transition: transform 0.35s ease-in-out;

  &:nth-child(2) {
    --button-color: var(--secondary-pink);
    --hover-rotate: -1deg;

    transform: rotate(2deg);
  }
  &:nth-child(3) {
    --button-color: var(--secondary-green);
  }

  &:focus,
  &:hover,
  &:active {
    --button-text-color: var(--ud-black);
    transform: rotate(var(--hover-rotate));
  }
`

const MainContainer = styled.div`
  width: 100%;
  padding-right: var(--site-main-gutter-mobile);
  padding-left: var(--site-main-gutter-mobile);

  @media ${device.tabletM} {
    max-width: 45.5rem;
    margin: 0 auto;
    padding-right: 0;
    padding-left: 0;
  }

  @media ${device.laptop} {
    max-width: none;
  }
`

const PresentationWrapper = styled.p`
  width: 100%;
  max-width: 100%;
  margin: 0 0 4.5rem 0;
`

const OtherInformation = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`

const SectionOtherInformation = styled.div`
  flex: 0 0 100%;
  margin: 0 0 3.125rem 0;

  &:last-child {
    margin: 0;
  }

  @media ${device.mobileL} {
    flex: 0 0 48%;
  }

  p {
    margin: 0 0 0.625rem 0;
  }

  a {
    transition: opacity 0.25s ease;

    &:focus,
    &:hover,
    &:active {
      opacity: 0.5;
    }
  }
`
