import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { CenteredMaxContentWidth } from "../shared/StyleMixins"
import { Button } from "../components/Button"
import { device } from "../shared/Device"

type Props = {
  data: {
    buttonOne: string
    buttonOneLinkTo: string
    buttonTwo: string
    buttonTwoLinkTo: string
    buttonThree: string
    buttonThreeLinkTo: string
  }
}

export interface IButton {
  text: string
  link: string
}

export const Buttons: React.FC<Props> = ({ data }) => {
  const [buttonsArray, setButtonsArray] = useState<IButton[]>([])
  const {
    buttonOne,
    buttonOneLinkTo,
    buttonTwo,
    buttonTwoLinkTo,
    buttonThree,
    buttonThreeLinkTo,
  } = data

  useEffect(() => {
    setButtonsArray([
      { text: buttonOne, link: buttonOneLinkTo },
      { text: buttonTwo, link: buttonTwoLinkTo },
      { text: buttonThree, link: buttonThreeLinkTo },
    ])
  }, [])

  return (
    <SectionWrapper>
      {buttonsArray?.map((buttonObj, index) => {
        return <Button button={buttonObj} index={index} key={index} />
      })}
    </SectionWrapper>
  )
}

const SectionWrapper = styled.div`
  ${CenteredMaxContentWidth};
  max-width: var(--hero-max-width);
`
