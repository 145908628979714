import React, { useEffect, useState, useRef } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { GatsbyImageType } from "../shared/ContentfulTypes"
import styled from "styled-components"
import { CenteredMaxContentWidth } from "../shared/StyleMixins"
import { device } from "../shared/Device"
import { getUrlLocation } from "../utils/getUrlLocation"
import Sticker from "../components/Sticker"
import ReactPlayer from "react-player/lazy"
import Play_button from "../images/play_btn.svg"
import Pause_button from "../images/pause_btn.svg"

type HeroProps = {
  data: {
    __typename: string
    id: string
    heading: string
    ingress: { ingress: string }
    image: any
    links: []
    fullSize: Boolean
    buttonTextOne: string
    buttonTextTwo: string
    buttonTextThree: string
    sticker: string
  }
}

export const Hero: React.FC<HeroProps> = ({ data }) => {
  const { heading, image, sticker } = data
  const [mediaType, setMediaType] = useState("")
  const [playing, setPlaying] = useState(true)
  const vidRef = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    if (mediaType === "Video" && vidRef.current) {
      vidRef.current.volume = 0
    }
  }, [])

  const [overlappingText, setOverlappingText] = useState(false)
  const [heroMargin, setHeroMargin] = useState("")

  const location = getUrlLocation()
  useEffect(() => {
    location == "handla-online"
      ? setOverlappingText(true)
      : setOverlappingText(false)

    location == "vad-ar-pa-gang"
      ? setHeroMargin("-7rem")
      : setHeroMargin("2rem")
  }, [overlappingText, heroMargin])

  const handleClick = () => {
    if (vidRef.current) {
      setPlaying(!playing)
    }
  }

  useEffect(() => {
    setMediaType(
      (image?.gatsbyImageData as GatsbyImageType) === null
        ? "Video"
        : "gatsbyImageData"
    )
  }, [mediaType])

  return (
    <>
      <StyledSection heroMargin={heroMargin} className="hero-section">
        {mediaType === "Video" ? (
          <VideoWrapper heroMargin={heroMargin} onClick={handleClick}>
            <ControlBUttons>
              {playing ? (
                <Pause_button className="show_btn pauseBtn" />
              ) : (
                <Play_button className="show_btn playBtn" />
              )}
            </ControlBUttons>
            <StyledVid>
              <ReactPlayer
                url={`${image?.file?.url}`}
                loop={true}
                playsinline={true}
                playing={playing}
                width="100%"
                height="100%"
                volume={0}
                muted={true}
                fallback={<Pause_button />}
                /* @ts-ignore */
                ref={vidRef}
              />
            </StyledVid>
          </VideoWrapper>
        ) : null}

        {overlappingText ? (
          <>
            <Heading>{heading}</Heading>
            {image.gatsbyImageData && (
              <GatsbyImage
                image={image?.gatsbyImageData}
                alt={image?.description || "missing"}
                className="hero-image tall"
              />
            )}
          </>
        ) : (
          <>
            {image.gatsbyImageData && (
              <GatsbyImage
                image={image?.gatsbyImageData}
                alt={image?.description || "missing"}
                className="hero-image"
              />
            )}
          </>
        )}
        {sticker && sticker !== "No sticker" ? (
          <StickerWrapper>
            <Sticker sticker={sticker.toLowerCase()} />
          </StickerWrapper>
        ) : null}
      </StyledSection>
    </>
  )
}

type StyledProps = {
  heroMargin: string
}

const StyledSection = styled.div<StyledProps>`
  position: relative;
  ${CenteredMaxContentWidth};
  max-width: var(--hero-max-width);
  margin-bottom: ${(StyledProps) => StyledProps.heroMargin};

  .hero-image {
    height: 0;
    padding-bottom: 49.25946633%;
    aspect-ratio: unset;

    @supports (aspect-ratio: 2.03 / 1) {
      height: auto;
      padding-bottom: 0;
      aspect-ratio: 2.03 / 1;
    }

    &.tall {
      height: 0;
      padding-bottom: 100%;
      aspect-ratio: unset;

      @supports (aspect-ratio: 1 / 1) {
        height: auto;
        padding-bottom: 0;
        aspect-ratio: 1 / 1;
      }

      @media ${device.tablet} {
        height: 0;
        padding-bottom: 49.25946633%;
        aspect-ratio: unset;

        @supports (aspect-ratio: 2.03 / 1) {
          height: auto;
          padding-bottom: 0;
          aspect-ratio: 2.03 / 1;
        }
      }

      @media ${device.laptopL} {
        height: 0;
        aspect-ratio: unset;
        padding-bottom: 43.49702073%;

        @supports (aspect-ratio: 2.299 / 1) {
          height: auto;
          padding-bottom: 0;
          aspect-ratio: 2.299 / 1;
        }
      }
    }

    @media ${device.laptopL} {
      height: 0;
      aspect-ratio: unset;
      padding-bottom: 43.49702073%;

      @supports (aspect-ratio: 2.299 / 1) {
        height: auto;
        padding-bottom: 0;
        aspect-ratio: 2.299 / 1;
      }
    }
  }
`

const Heading = styled.h1`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  text-align: center;
  z-index: 2;
  margin-bottom: 0;

  @media ${device.tabletM} {
    width: 70%;
  }

  @media ${device.laptop} {
    width: 60%;
  }

  @media ${device.laptopL} {
    width: 40%;
  }
`

const StickerWrapper = styled.div`
  position: absolute;
  width: 6.25rem;
  height: 6.25rem;
  bottom: -2rem;
  right: -1rem;
  transform: rotate(10deg);
  z-index: 99;
  pointer-events: none;

  @media ${device.tabletM} {
    width: 12rem;
    height: 12rem;
    bottom: -5.5rem;
    right: -3rem;
  }

  @media ${device.laptop} {
    top: calc(50% - 2rem);
    right: calc(20% - 15rem);
  }

  @media ${device.laptopL} {
    top: calc(50% - 3rem);
    right: calc(30% - 11rem);
  }
`

const VideoWrapper = styled.div<StyledProps>`
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  aspect-ratio: 100 / (640 / 360);
  max-width: 1800px;
  height: 0;
  padding-bottom: 46.25%;
  cursor: pointer;

  .show_btn {
    margin: 0;
    padding: 0;
    opacity: 1;
  }

  &:hover .show_btn {
    opacity: 1;
  }

  @media ${device.laptop} {
    .show_btn {
      opacity: 0;
    }
  }

  @media (min-width: 1024px) {
    .video-wrapper {
      padding-bottom: 50%;
    }
  }

  @supports (aspect-ratio: 2 / 1) {
    .video-wrapper {
      height: auto;
      padding-bottom: 0;
      aspect-ratio: 16 / 9;
    }

    @media (min-width: 1024px) {
      .video-wrapper {
        aspect-ratio: 2 / 1;
      }
    }
  }
`

const ControlBUttons = styled.div`
  .playBtn {
    position: absolute;
    transform: translate(-50%, -50%);
    z-index: 2;
    top: 85%;
    left: 8%;
    width: 15%;
    height: 15%;
  }
  .pauseBtn {
    position: absolute;
    z-index: 2;
    transform: translate(-50%, -50%);
    width: 30%;
    height: 30%;
    top: 85%;
    left: 5%;
  }

  @media ${device.tabletM} {
    .pauseBtn {
      width: 30%;
      height: 30%;
      top: 85%;

      left: 5%;
    }
  }
`

const StyledVid = styled.div`
  position: absolute;
  cursor: pointer;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
`
