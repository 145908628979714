export const normalizeString = (str: string) => {
  if (!str) {
    return
  } else {
    const newStr = str
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .split("/")[0]
      .replace(/\s/g, "")
      .toLowerCase()
    return newStr
  }
}
