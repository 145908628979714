import React, { useContext, useRef, useState } from "react"
import styled, { css } from "styled-components"
import { ILocaleContext, LocaleContext } from "../context/localeContext"
import { device } from "../shared/Device"
import { CenteredMaxContentWidth, PillButton } from "../shared/StyleMixins"
import * as translations from "../utils/translations"

export const RoomSearch: React.FC = () => {
  const { locale } = useContext<ILocaleContext>(LocaleContext)
  const checkInCalendarRef = useRef<HTMLInputElement>()
  const checkOutCalendarRef = useRef<HTMLInputElement>()

  const date = new Date()
  const tomorrow = new Date(date.getTime() + 24 * 60 * 60 * 1000)
    .toISOString()
    .split("T")[0]

  const [todaysDate, setTodaysDate] = useState(date.toISOString().split("T")[0])

  const translation =
    locale === "en" ? translations.roomSearch.en : translations.roomSearch.sv

  const setMaxChildren = (numberOfAdults: number) => {
    let maxNumberOfChildren = 3 - numberOfAdults

    document
      .querySelector(".numberOfChildren")
      ?.setAttribute("max", maxNumberOfChildren.toString())
  }

  const setMaxAdults = (numberOfChildren: number) => {
    let maxNumberOfAdults = numberOfChildren == 0 ? 2 : 3 - numberOfChildren
    document
      .querySelector(".numberOfAdults")
      ?.setAttribute("max", maxNumberOfAdults.toString())
  }

  const handleClick: React.MouseEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault()
    /* @ts-ignore */
    let promoText = document.querySelector(".promoText")?.value
    /* @ts-ignore */
    let checkInDate = document.querySelector(".checkInDate")?.value
    /* @ts-ignore */
    let checkOutDate = document.querySelector(".checkOutDate")?.value
    /* @ts-ignore */
    let numberOfAdults = document.querySelector(".numberOfAdults")?.value
    /* @ts-ignore */
    let numberOfChildren = document.querySelector(".numberOfChildren")?.value
    let targetUrl = `https://urbandeli.nitesoft.se/units/${checkInDate}/${checkOutDate}/${promoText}/${numberOfAdults}/${numberOfChildren}`
    window.open(targetUrl)
  }

  let checkOutDate =
    typeof window !== "undefined"
      ? (document.querySelector(".checkOutDate") as HTMLInputElement)
      : null

  const handleCheckInDate = (date: string) => {
    setTodaysDate(date)
    let tomorrow = new Date(date)
    tomorrow.setDate(tomorrow.getDate() + 1)
    const tomorrowDateString = tomorrow.toISOString().split("T")[0]
    document
      .querySelector(".checkOutDate")
      ?.setAttribute("value", tomorrowDateString)

    if (checkOutDate) {
      checkOutDate.value = tomorrowDateString
    }
  }

  const handleCheckInCalendarClick = () => {
    checkInCalendarRef?.current?.showPicker()
  }
  const handleCheckOutCalendarClick = () => {
    checkOutCalendarRef?.current?.showPicker()
  }

  return (
    <StyledSection>
      <FormWrapper>
        <InputWrapper>
          <FormFieldWrapper
            onClick={handleCheckInCalendarClick}
            className="checkInField"
          >
            <InputLabel>{translation.checkIn}</InputLabel>
            <InputDate
              /* @ts-ignore */
              ref={checkInCalendarRef}
              type="date"
              className="checkInDate"
              defaultValue={todaysDate}
              onChange={(e) => {
                handleCheckInDate(e.target.value)
              }}
            />
          </FormFieldWrapper>
        </InputWrapper>
        <InputWrapper>
          <FormFieldWrapper onClick={handleCheckOutCalendarClick}>
            <InputLabel>{translation.checkOut}</InputLabel>
            <InputDate
              type="date"
              /* @ts-ignore */
              ref={checkOutCalendarRef}
              className="checkOutDate"
              defaultValue={tomorrow}
            />
          </FormFieldWrapper>
        </InputWrapper>
        <InputWrapper>
          <FormFieldWrapper>
            <InputLabel>{translation.nrAdults}</InputLabel>
            <InputNumber
              type="number"
              placeholder="0"
              min="1"
              max="2"
              defaultValue="2"
              className="numberOfAdults"
              onChange={(e) => {
                setMaxChildren(parseInt(e.target.value))
              }}
            />
          </FormFieldWrapper>
        </InputWrapper>
        <InputWrapper>
          <FormFieldWrapper>
            <InputLabel>{translation.nrChildren}</InputLabel>
            <InputNumber
              type="number"
              placeholder="0"
              min="0"
              max="1"
              className="numberOfChildren"
              onChange={(e) => {
                setMaxAdults(parseInt(e.target.value))
              }}
            />
          </FormFieldWrapper>
        </InputWrapper>
        <InputWrapper>
          <FormFieldWrapper>
            <InputLabel>{translation.promoteCode}</InputLabel>
            <InputDate
              type="text"
              placeholder={translation.placeHolder}
              className="promoText"
            />
          </FormFieldWrapper>
        </InputWrapper>
        <InputWrapper>
          <StyledButton
            type="button"
            value={translation.search}
            onClick={handleClick}
          />
        </InputWrapper>
      </FormWrapper>
    </StyledSection>
  )
}

const StyledSection = styled.div`
  ${CenteredMaxContentWidth};
  max-width: 25rem;
  margin-bottom: 8rem;
  @media ${device.tabletM} {
    max-width: 68.75rem;
  }
`

const FormWrapper = styled.div`
  .checkInField {
  }

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 auto 3.5rem auto;

  @media ${device.tabletM} {
    margin: 0 10rem;
  }

  @media ${device.laptop} {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 0 0 0;
  }
`

const InputWrapper = styled.div`
  flex: 0 0 48%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 0 0 1rem 0;
  line-height: 1.5;

  @media ${device.laptop} {
    max-width: 10.5rem;
  }
`

const InputLabel = styled.label`
  font-size: 0.775rem;
  padding: 0 0.5rem;
`

const FormField = css`
  border: none;
  position: relative;
  appearance: none;
  width: 100%;
  color: var(--ud-black);
  font-family: var(--font-text);
  font-size: 1rem;
  text-transform: none;
  background: none;
  outline: none;
  margin: 0;
  padding: 0 0.5rem;

  @media ${device.tabletM} {
  }
`
const FormFieldWrapper = styled.div`
  input[type="date"]::-webkit-calendar-picker-indicator {
    background-position: right;
    background-size: auto;
    cursor: pointer;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    width: auto;
    height: auto;
  }
  border: none;
  appearance: none;
  width: 100%;
  color: var(--ud-black);
  font-family: var(--font-text);
  font-size: 1rem;
  border: 1px solid var(--ud-black);
  outline: none;
  margin: 0;
  padding: 0.5rem 0.5rem;
  min-height: 4.373rem;
  cursor: pointer;

  @media ${device.tabletM} {
  }
`

const InputDate = styled.input`
  ${FormField}
`

const InputNumber = styled.input`
  ${FormField}
`

const StyledButton = styled.input`
  ${PillButton};
  min-height: 4.373rem;
  width: 100%;

  @media ${device.mobile} {
    min-height: 2.75rem;
  }

  @media ${device.mobile} {
    min-height: 4.373rem;
  }
`
