import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImageType } from "../shared/ContentfulTypes"

export type LocationNode = {
  contactHeading: string
  location: string
  coordinates: {
    lat: number
    lon: number
  }
  contactInformation: {
    contactInformation: string
  }
  hotelInformation: {
    hotelInformation: string
  }
  addressInformation: {
    addressInformation: string
  }
  addressHeading: string
  hotelHeading: string
  markerIcon: {
    description: string
    gatsbyImageData: GatsbyImageType
    file: {
      url: string
    }
  }
  mapPopupTitle: string
  mapPopupDescription: {
    mapPopupDescription: string
  }
  buttonTextOne: string
  buttonOneLinksTo: string
}

type MapLocationsQueryResult = {
  allContentfulLocation: {
    nodes: LocationNode[]
  }
}

export const useMapLocationsEn = () => {
  const mapLocations = useStaticQuery<MapLocationsQueryResult>(graphql`
    {
      allContentfulLocation(
        filter: {
          location: {
            in: ["Sveavagen/Rooftop", "Sickla-en", "Nytorget-en", "Hotel"]
          }
          node_locale: { eq: "en" }
        }
      ) {
        nodes {
          ...contentMapLocation
        }
      }
    }
  `)
  return mapLocations
}
