import * as Sections from "../sections"

export type Components = {
  [sectionName: string]: React.ElementType
}

const {
  Hero,
  Slider,
  Calendar,
  RichTextColumns,
  PromoteCTA,
  LocationPresentation,
  FoodMenu,
  FindUs,
  PromoteNoCTA,
  Closeby,
  DoublePhotos,
  HotelRooms,
  QandA,
  TurningImages,
  ContactUs,
  Presentation,
  InfoText,
  Buttons,
  // UDonTheRoadMap,
  RoomSearch,
  // InstagramFeed,
  Subscription,
  InfoPage,
  CampaignLandingPage,
} = Sections

export const SectionComponents: Components = {
  ContentfulSectionHero: Hero,
  ContentfulSectionSlideshow: Slider,
  ContentfulSectionCalendar: Calendar,
  ContentfulSectionRichTextColumns: RichTextColumns,
  ContentfulSectionPromoteCta: PromoteCTA,
  ContentfulSectionLocationPresentation: LocationPresentation,
  ContentfulSectionFoodMenu: FoodMenu,
  ContentfulSectionFindUs: FindUs,
  ContentfulSectionPromoteNoCta: PromoteNoCTA,
  ContentfulSectionStepper: Closeby,
  ContentfulSectionImageCarousel: HotelRooms,
  ContentfulSectionQuestionsAndAnswers: QandA,
  ContentfulSectionShopifyCards: TurningImages,
  ContentfulSectionContactUs: ContactUs,
  ContentfulSectionPresentation: Presentation,
  ContentfulInfoText: InfoText,
  ContentfulSectionButtons: Buttons,
  // ContentfulSectionUdOnTheRoadMap: UDonTheRoadMap,
  ContentfulSectionRoomSearch: RoomSearch,
  // ContentfulSectionInstagramFeed: InstagramFeed,
  ContentfulSectionSubscription: Subscription,
  ContentfulSectionInfoPage: InfoPage,
  ContentfulCampaignLandingPage: CampaignLandingPage,
  ContentfulSectionPhotos: DoublePhotos,
}
