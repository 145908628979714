import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImageType } from "../shared/ContentfulTypes"

type Props = {
  sticker: String
}

const Sticker: React.FC<Props> = ({ sticker }) => {
  let imageData
  const data = useStaticQuery(graphql`
    query AllAssets {
      allContentfulAsset(filter: { title: { regex: "/Sticker/" } }) {
        edges {
          node {
            gatsbyImageData
            title
          }
        }
      }
    }
  `)
  const {
    allContentfulAsset: { edges: images },
  } = data
  type ImageObjTypes = {
    image: GatsbyImageType
    node: { gatsbyImageData: GatsbyImageType; title: String }
  }

  images.map((imageObj: ImageObjTypes) => {
    if (
      imageObj.node.title.toLocaleLowerCase() == sticker.toLocaleLowerCase()
    ) {
      imageData = imageObj.node.gatsbyImageData || {}

      return imageData
    }
  })

  return (
    <>
      {imageData ? (
        <Wrapper>
          <AnimationWrapper>
            <GatsbyImage image={imageData} alt={""} />
          </AnimationWrapper>
        </Wrapper>
      ) : null}
    </>
  )
}

const Wrapper = styled.div`
  z-index: 99;
`
const AnimationWrapper = styled.div`
  animation: float 3.5s ease-in-out infinite;

  @keyframes float {
    0% {
      transform: translateY(12px) rotate(-2deg);
    }
    50% {
      transform: translateY(0) rotate(2deg);
    }
    100% {
      transform: translateY(12px) rotate(-2deg);
    }
  }
`
export default Sticker
