import { renderRichText } from "gatsby-source-contentful/rich-text"
import { ContentfulRichText } from "../shared/ContentfulTypes"

// Again, no clue whatäs going on.
export const seperateRichText = (richText: ContentfulRichText) => {
  let column: React.ReactNode[] = []
  const seperatedRichText: React.ReactNode[] = []
  const formattedInformation = renderRichText(richText)

  /* @ts-ignore */
  formattedInformation.forEach((el: RichTextNode, i) => {
    if (el?.type === "h3" && i !== 0) {
      seperatedRichText.push(column)
      column = []
    }
    column.push(el)
    /* @ts-ignore */
    if (i === formattedInformation.length - 1) {
      seperatedRichText.push(column)
    }
  })
  return seperatedRichText
}
