import { Link } from "gatsby"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import React, { useContext, useEffect, useState } from "react"
import styled, { css } from "styled-components"
import { ILocaleContext, LocaleContext } from "../context/localeContext"
import { GatsbyImageType } from "../shared/ContentfulTypes"
import { device } from "../shared/Device"
import { SquareButton } from "../shared/StyleMixins"
import * as translations from "../utils/translations"

type SubscriptionTypes = {
  subscription: {
    heading: string
    infoText: { raw: string }
    whatsIncludedHeading: string
    image: GatsbyImageType
    includedItems: IncludedItem[]
  }
}

type IncludedItem = {
  image: {
    gatsbyImageData: IGatsbyImageData
  }
  heading: string
}

export const SubscriptionCard: React.FC<SubscriptionTypes> = ({
  subscription,
}) => {
  const { locale } = useContext(LocaleContext) as ILocaleContext
  const translation =
    locale === "en"
      ? translations.subscriptionCard.en
      : translations.subscriptionCard.sv
  const [moreThenFiveItems, setMoreThenFiveItems] = useState(false)

  useEffect(() => {
    setMoreThenFiveItems(subscription.includedItems.length < 6 ? false : true)
  }, [])

  return (
    <SubscriptionsWrapper>
      <OptionHeading>{subscription.heading}</OptionHeading>
      <InfoText>
        {renderRichText({
          raw: subscription.infoText.raw,
          references: [{ __typename: "string", contentful_id: "string" }],
        })}
      </InfoText>
      <Container>
        <StyledLink to={"#contactus"}>{translation.button}</StyledLink>
      </Container>
      <IncludedItems>
        <IncludedHeader>{subscription.whatsIncludedHeading}</IncludedHeader>
        <ItemsContainer howManycolumns={moreThenFiveItems}>
          {subscription.includedItems.map((item, index) => (
            <Item key={index}>
              <ItemIcon>
                <GatsbyImage
                  image={item.image.gatsbyImageData}
                  alt={"Missing"}
                />
              </ItemIcon>
              <ItemHeading>{item.heading}</ItemHeading>
            </Item>
          ))}
        </ItemsContainer>
      </IncludedItems>
    </SubscriptionsWrapper>
  )
}

type StyledProps = {
  howManycolumns: boolean
}

const Container = styled.div`
  @media ${device.laptop} {
    grid-area: 3 / 1 / 4 / 2;
  }
`

const Heading = styled.h2`
  text-align: center;
  margin-bottom: 4.125rem;
  max-width: 50rem;
`

const SubscriptionsWrapper = styled.div`
  display: grid;
  grid-row-gap: 16px;
  place-content: center;

  @media ${device.laptop} {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto auto 1fr;
    max-width: 1054px;
    grid-column-gap: 8.56rem;
  }
`

const OptionHeading = styled.h3`
  grid-area: 1 / 1 / 2 / 2;

  @media ${device.laptop} {
    grid-area: 1 / 1 / 2 / 3;
    margin-bottom: 2.5rem;
  }
`
const InfoText = styled.p`
  grid-area: 2 / 1 / 3 / 2;

  @media ${device.laptop} {
    grid-area: 2 / 1 / 3 / 2;
    max-width: 33rem;
  }
`

const StyledLink = styled(Link)`
  ${SquareButton};
  grid-area: 4 / 1 / 5 / 2;

  --button-background-color: var(--secondary-blue);
  --button-border-color: var(--button-background-color);
  transform: rotate(-2deg);
  width: 93vw;
  margin-bottom: 5rem;

  @media ${device.laptop} {
    margin: 3rem 0 9.37rem 0;
    max-width: 19.5rem;
    grid-area: 3 / 1 / 4 / 2;
  }
`
const IncludedHeader = styled.h3`
  margin-bottom: 1.5rem;
  width: 100%;
`
const ItemsContainer = styled.div<StyledProps>`
  display: grid;

  ${(StyledProps) =>
    StyledProps.howManycolumns
      ? css`
          grid-template-columns: repeat(2, 1fr);
        `
      : css`
          grid-template-columns: repeat(1, 1fr);
        `}
  grid-template-rows: repeat(5, auto);
`

const IncludedItems = styled.div`
  grid-area: 3 / 1 / 4 / 2;

  @media ${device.laptop} {
    grid-area: 2 / 2 / 4 / 3;
  }
`
const Item = styled.div`
  display: flex;
`

const ItemHeading = styled.div``

const ItemIcon = styled.div`
  padding: 0 1.25rem 1.5rem 0;
  width: 2.5rem;
`
