import { Link } from "gatsby"
import React, { useContext } from "react"
import styled from "styled-components"
import { device } from "../shared/Device"
import { SquareButton } from "../shared/StyleMixins"
import { BookATableModal } from "./BookATableModal"
import { IModalContext, ModalContext } from "../context/modalContext"
import { getUrlLocation } from "../utils/getUrlLocation"
import { useGetHashData } from "../hooks/useGetHashData"
import { normalizeString } from "../utils/normalizeString"
import { IButton } from "../sections"

type Props = {
  button: IButton
  index: number
}

export const Button: React.FC<Props> = ({ button, index }) => {
  const data = useGetHashData()
  const edges = data.allContentfulLocation.edges

  const { toggleModal, showModal } = useContext(ModalContext) as IModalContext
  const currentLocation = getUrlLocation()

  const locationsWithOutModal = [
    "sveavagen",
    "nytorget",
    "sickla",
    "udpavag",
    "udontheroad",
    "hagastaden",
  ]
  const location = locationsWithOutModal.includes(currentLocation)
  const handleOnClick = () => {
    toggleModal()
  }
  const currentLocationHash = edges.filter(
    (node: { node: { location: string; buttonOneLinksTo: string } }) => {
      const normalizedLocation = normalizeString(node.node.location)

      const hashNumber =
        normalizedLocation == currentLocation && node.node.buttonOneLinksTo

      return hashNumber
    }
  )

  const toHashNumber = currentLocationHash[0]?.node.buttonOneLinksTo

  const { link, text } = button

  let renderSwitch = (index: number) => {
    switch (index) {
      case 0:
        return text ? (
          <StyledLink
            to={link}
            target={link?.indexOf("http") == 0 ? "_blank" : "_self"}
          >
            {text}
          </StyledLink>
        ) : null
      case 1:
        return text ? (
          <StyledLink
            to={link}
            target={link?.indexOf("http") == 0 ? "_blank" : "_self"}
          >
            {text}
          </StyledLink>
        ) : null
      case 2:
        return text && !location ? (
          <StyledLink
            onClick={handleOnClick}
            to={"#"}
            target={link?.indexOf("http") == 0 ? "_blank" : "_self"}
          >
            {text}
          </StyledLink>
        ) : currentLocation == "udpavag" || currentLocation == "udontheroad" ? (
          <StyledLink to={`${link}`}>{text}</StyledLink>
        ) : (
          <StyledLink
            to={`/${currentLocation}`}
            target={link?.indexOf("http") == 0 ? "_blank" : "_self"}
            data-hash={toHashNumber}
            className="waiteraid-widget"
          >
            {text}
          </StyledLink>
        )
      default:
        return null
    }
  }

  {
    return (
      <ButtonWrapper className={`button_${index}`}>
        {renderSwitch(index)}
        {showModal && <BookATableModal />}
      </ButtonWrapper>
    )
  }
}

const StyledLink = styled(Link)`
  ${SquareButton};
  padding: 1.25rem;
  --button-color: var(--secondary-blue);
  background-color: var(--button-color);
  border-color: var(--button-color);
  transition: transform 0.35s ease-in-out;

  &:focus,
  &:hover,
  &:active {
    --button-text-color: var(--ud-black);
    transform: rotate(-2deg);
  }

  @media ${device.mobile} {
    padding: 1.25rem 2.5rem;
  }

  @media ${device.tablet} {
    padding: 1.25rem 4rem;
  }

  @media ${device.laptopM} {
    padding: 1.25rem 7rem;
  }
`

const ButtonWrapper = styled.div`
  z-index: var(--site-hero-button-index);

  &.button_0 {
    margin-top: 1.2rem;
    width: 100%;

    ${StyledLink} {
      flex: 1;
      --button-color: var(--secondary-blue);

      @media ${device.tablet} {
        max-width: 19.5rem;

        flex: 0;
      }
    }
  }

  &.button_1,
  &.button_2 {
    position: fixed;
    right: var(--site-main-gutter-mobile, 0.625rem);
    bottom: 0;

    @media ${device.tabletM} {
      right: var(--site-main-gutter-tablet, 1.25rem);
    }

    @media ${device.laptop} {
      right: var(--site-main-gutter-laptop, 3.75rem);
    }

    @media ${device.desktopM} {
      right: calc((var(--vw, 100vw) - var(--hero-max-width, 112.5rem)) / 2);
    }
  }

  &.button_1 {
    transform: translateX(calc(-100% - 1.25rem));

    &:last-of-type {
      transform: translateX(0);
    }

    ${StyledLink} {
      --button-color: var(--secondary-green);

      &:focus,
      &:hover {
        transform: rotate(2deg);
      }
    }
  }
  &.button_2 {
    ${StyledLink} {
      --button-color: var(--ud-yellow);
      --button-border-color: var(--button-background-color);
      --button-text-color: var(--ud-black);

      .footer-visible & {
        --button-color: var(--ud-black);
        --button-border-color: var(--button-background-color);
        --button-text-color: var(--ud-yellow);
      }
    }
  }
`
