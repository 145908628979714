import React, { useContext, useEffect, useState } from "react"
import { ILocaleContext, LocaleContext } from "../../context/localeContext"
import * as translations from "../../utils/translations"
import ScruffyInputBackground from "../../images/scruffy__input-background.svg"
import ScruffyTextareaBackground from "../../images/scruffy__textarea-background.svg"
import {
  CheckboxLabel,
  CheckboxWrapper,
  FormContainer,
  FormFieldset,
  InputCheckbox,
  InputDate,
  InputEmail,
  InputLabel,
  InputSelect,
  InputText,
  InputTextarea,
  InputTime,
  InputWrapper,
  StyledForm,
  StyledThankYouForm,
  SubmitButton,
  ThankYouFormFieldset,
  ThankYouHeader,
  ThankYouText,
  TwoColumns,
  ValidationText,
  VisuallyHiddenLabel,
} from "./shared/FormStyling"

type FormValidation = {
  matter: boolean | null
  name: boolean | null
  email: boolean | null
  quantity: boolean | null
  phoneNumber: boolean | null
  message: boolean | null
  subscription: boolean | null
  company: boolean | null
}

type CateringForm = {
  matter?: string
}

export const CateringForm: React.FC<CateringForm> = ({ matter }) => {
  const { locale } = useContext(LocaleContext) as ILocaleContext
  const translation =
    locale === "en"
      ? translations.cateringForm.en
      : translations.cateringForm.sv

  const [validations, setValidations] = useState<FormValidation>({
    matter: matter === undefined ? null : false,
    name: null,
    email: null,
    quantity: null,
    phoneNumber: null,
    message: null,
    company: null,
    subscription: null,
  })
  const [noneRequiredValidations, setNoneRequiredValidations] = useState({
    startDate: null,
    endDate: null,
  })

  const [showDate, setShowDate] = useState(false)
  const [showSubscription, setShowSubscription] = useState(false)
  const [showCompany, setShowCompany] = useState(false)
  const [showThankYou, setShowThankYou] = useState(false)
  const [url, setUrl] = useState("")
  const [today] = useState(new Date().toISOString().split("T")[0])
  const [values, setValues] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    quantity: "",
    company: "",
    matter: matter ? matter : "",
    startDate: "",
    endDate: "",
    startTime: "00:00",
    endTime: "00:00",
    message: "",
    flexibleDate: "",
    subscription: "",
  })

  const handleNoneRequiredValidations = (event: any) => {
    setNoneRequiredValidations({
      ...noneRequiredValidations,
      [event.target.name]: !event.target.validity.valid,
    })
  }

  const handleValidation = (event: any) => {
    setValidations({
      ...validations,
      [event.target.name]: !event.target.validity.valid,
    })
  }

  const handleShowForm = (event: any) => {
    setShowThankYou(false)
  }

  useEffect(() => {
    setShowThankYou(location.search === "?ThankYou")
    setUrl(location.href ? location.origin + location.pathname : "")
  }, [])

  const handleSubmit = (e: any) => {
    // TOOD: illegal update?
    Object.keys(validations).forEach(
      (key) =>
        (validations[key as keyof FormValidation] =
          validations[key as keyof FormValidation] === null ||
          validations[key as keyof FormValidation] === true
            ? true
            : false)
    )
    setValidations({ ...validations })
  }

  const handleChange = (event: any) => {
    setValues({
      ...values,
      [event.target.name]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value,
    })
    if (validations[event.target.name as keyof FormValidation] === true) {
      handleValidation(event)
    }
  }

  useEffect(() => {
    setShowSubscription(values.matter === "subscription")
    setShowDate(
      values.matter === "catering" || values.matter === "event/meeting"
    )
    setShowCompany(
      values.matter === "catering" || values.matter === "event/meeting"
    )
  }, [values.matter])

  return (
    <FormContainer>
      {showThankYou && (
        <StyledThankYouForm>
          <ThankYouHeader>{translation.thankYouHeader}</ThankYouHeader>
          <ThankYouText>{translation.thankYouText}</ThankYouText>
          <ThankYouFormFieldset>
            <SubmitButton
              type="submit"
              value="Nytt Meddelande"
              onClick={handleShowForm}
            />
          </ThankYouFormFieldset>
        </StyledThankYouForm>
      )}
      <StyledForm
        opacity={showThankYou}
        action="https://formsubmit.co/catering@urbandeli.se"
        // action={`https://formsubmit.co/olof.marklund@frankdigital.se`}
        method="POST"
        onSubmit={handleSubmit}
      >
        {/* <input
          type="hidden"
          name="_autoresponse"
          value={`Hej ${values.name}, vi har tagit emot ditt mail och svarar så fort vi kan!
            Med vänliga hälsningar, Urban Deli`}
        /> */}
        <input type="hidden" name="_template" value="table" />
        <input type="hidden" name="_next" value={url + "#contactus"} />
        <FormFieldset>
          <InputWrapper>
            <input
              type="hidden"
              name="_url"
              value="https://urbandeli.se/catering.html"
            ></input>
            <VisuallyHiddenLabel htmlFor="matter">
              {translation.typeOfEvent}*
            </VisuallyHiddenLabel>
            <InputSelect
              name="matter"
              id="matter"
              placeholder={translation.matter}
              required
              value={values.matter}
              onChange={handleChange}
              className={validations.matter ? "is-touched" : ""}
              onBlur={handleValidation}
            >
              <option value="">{translation.typeOfEvent}*</option>
              <option value="catering">{translation.catering}</option>
              <option value="event/meeting">{translation.eventMeetings}</option>
              <option value="subscription">{translation.subscription}</option>
            </InputSelect>
            <ScruffyInputBackground />
          </InputWrapper>
          {validations.matter && (
            <ValidationText>{translation.choseTypeOfEvent}</ValidationText>
          )}
        </FormFieldset>
        {showCompany && (
          <FormFieldset>
            <InputWrapper>
              <VisuallyHiddenLabel htmlFor="company">
                {translation.iam}*
              </VisuallyHiddenLabel>
              <InputSelect
                name="company"
                id="company"
                required
                value={values.company}
                onChange={handleChange}
                className={validations.company ? "is-touched" : ""}
                onBlur={handleValidation}
              >
                <option value="">{translation.iam}*</option>
                <option value="company">{translation.company}</option>
                <option value="person">{translation.typeOfPerson}</option>
              </InputSelect>
              <ScruffyInputBackground />
            </InputWrapper>
            {validations.company && (
              <ValidationText>{translation.choseTypOfOrderer}</ValidationText>
            )}
          </FormFieldset>
        )}
        {showSubscription && (
          <FormFieldset>
            <InputWrapper>
              <VisuallyHiddenLabel htmlFor="subscription">
                {translation.typeOfSubscription}*
              </VisuallyHiddenLabel>
              <InputSelect
                name="subscription"
                id="subscription"
                required
                value={values.subscription}
                onChange={handleChange}
                className={validations.subscription ? "is-touched" : ""}
                onBlur={handleValidation}
              >
                <option value="">{translation.typeOfSubscription}*</option>
                <option value="classic">{translation.classicer}</option>
                <option value="medium">{translation.between}</option>
                <option value="all-in">All-in</option>
              </InputSelect>
              <ScruffyInputBackground />
            </InputWrapper>
            {validations.subscription && (
              <ValidationText>{translation.typeOfSubscription}</ValidationText>
            )}
          </FormFieldset>
        )}
        <FormFieldset>
          <InputWrapper>
            <VisuallyHiddenLabel htmlFor="name">
              {showSubscription
                ? "Företag*"
                : values.company === "company"
                ? "Företag*"
                : "Namn*"}
            </VisuallyHiddenLabel>
            <InputText
              type="text"
              name="name"
              id="name"
              placeholder={
                showSubscription || values.company === "company"
                  ? `${translation.enterCompany}*`
                  : `${translation.enterName}*`
              }
              required
              className={validations.name ? "is-touched" : ""}
              onBlur={handleValidation}
              value={values.name}
              onChange={handleChange}
            />
            <ScruffyInputBackground />
          </InputWrapper>
          {validations.name && (
            <ValidationText>
              {" "}
              {showSubscription || values.company === "company"
                ? "Ange namn på företag"
                : "Ange ditt för- och efternamn"}
            </ValidationText>
          )}
        </FormFieldset>
        <FormFieldset>
          <InputWrapper>
            <VisuallyHiddenLabel htmlFor="quantity">
              {translation.numberOfGuests}
            </VisuallyHiddenLabel>
            <InputText
              type="number"
              name="quantity"
              id="quantity"
              placeholder={`${translation.numberOfGuests}`}
              required
              className={validations.quantity ? "is-touched" : ""}
              onBlur={handleValidation}
              value={values.quantity}
              onChange={handleChange}
            />
            <ScruffyInputBackground />
          </InputWrapper>
          {validations.quantity && (
            <ValidationText>{translation.numberOfGuests}</ValidationText>
          )}
        </FormFieldset>
        {showDate && (
          <>
            <TwoColumns>
              <FormFieldset>
                <InputWrapper>
                  <InputLabel htmlFor="startDate" id="labelPadding">
                    {translation.choseStartDay}
                  </InputLabel>
                  <InputDate
                    type="date"
                    name="startDate"
                    id="startDate"
                    value={values.startDate}
                    className={
                      values.startDate === ""
                        ? noneRequiredValidations.startDate === null
                          ? ""
                          : "is-touched"
                        : noneRequiredValidations.startDate
                        ? "date-entry is-touched"
                        : "date-entry"
                    }
                    onChange={handleChange}
                    min={today}
                    max={"9999-12-30"}
                    onBlur={handleNoneRequiredValidations}
                  />
                  <ScruffyInputBackground />
                </InputWrapper>
                {noneRequiredValidations.startDate && (
                  <ValidationText>
                    {translation.choseValidDateafter} {today}
                  </ValidationText>
                )}
              </FormFieldset>
              <FormFieldset>
                <InputWrapper>
                  <InputLabel htmlFor="endDate">
                    {translation.endDate}
                  </InputLabel>
                  <InputDate
                    type="date"
                    name="endDate"
                    id="endDate"
                    className={
                      values.endDate === ""
                        ? noneRequiredValidations.endDate === null
                          ? ""
                          : "is-touched"
                        : noneRequiredValidations.endDate
                        ? "date-entry is-touched"
                        : "date-entry"
                    }
                    value={values.endDate}
                    onChange={handleChange}
                    min={today > values.startDate ? today : values.startDate}
                    max={"9999-12-30"}
                    onBlur={handleNoneRequiredValidations}
                  />
                  <ScruffyInputBackground />
                </InputWrapper>
                {noneRequiredValidations.endDate && (
                  <ValidationText>
                    {translation.choseValidDateafter}{" "}
                    {today > values.startDate ? today : values.startDate}
                  </ValidationText>
                )}
              </FormFieldset>
            </TwoColumns>
            <TwoColumns>
              <FormFieldset>
                <InputWrapper>
                  <InputLabel htmlFor="starTime">
                    {translation.startTime}
                  </InputLabel>
                  <InputTime
                    type="time"
                    name="startTime"
                    id="startTime"
                    className={
                      values.startTime === "00:00"
                        ? ""
                        : "date-entry is-touched"
                    }
                    value={values.startTime}
                    onChange={handleChange}
                  />
                  <ScruffyInputBackground />
                </InputWrapper>
              </FormFieldset>
              <FormFieldset>
                <InputWrapper>
                  <InputLabel htmlFor="endTime">
                    {translation.endTime}
                  </InputLabel>
                  <InputTime
                    type="time"
                    name="endTime"
                    id="endTime"
                    className={
                      values.endTime === "00:00" ? "" : "date-entry is-touched"
                    }
                    value={values.endTime}
                    onChange={handleChange}
                  />
                  <ScruffyInputBackground />
                </InputWrapper>
              </FormFieldset>
            </TwoColumns>
            <FormFieldset>
              <CheckboxWrapper>
                <InputCheckbox
                  type="checkbox"
                  name="flexibleDate"
                  value={values.flexibleDate}
                  onChange={handleChange}
                />
                <CheckboxLabel>{translation.flexibleDate}</CheckboxLabel>
              </CheckboxWrapper>
            </FormFieldset>
          </>
        )}
        <FormFieldset>
          <InputWrapper>
            <VisuallyHiddenLabel htmlFor="email">
              {translation.emailCatering}*
            </VisuallyHiddenLabel>
            <InputEmail
              type="email"
              name="email"
              id="email"
              placeholder={`${translation.email}`}
              required
              className={validations.email ? "is-touched" : ""}
              onBlur={handleValidation}
              value={values.email}
              onChange={handleChange}
            />
            <ScruffyInputBackground />
          </InputWrapper>
          {validations.email && (
            <ValidationText>{translation.enterValidMail}</ValidationText>
          )}
        </FormFieldset>
        <FormFieldset>
          <InputWrapper>
            <VisuallyHiddenLabel htmlFor="phoneNumber">
              {translation.phoneNr}*
            </VisuallyHiddenLabel>
            <InputEmail
              type="tel"
              id="phoneNumber"
              name="phoneNumber"
              placeholder={`${translation.phoneNr}`}
              required
              className={validations.phoneNumber ? "is-touched" : ""}
              onBlur={handleValidation}
              value={values.phoneNumber}
              onChange={handleChange}
            />
            <ScruffyInputBackground />
          </InputWrapper>
          {validations.phoneNumber && (
            <ValidationText>{translation.validPhonenr}</ValidationText>
          )}
        </FormFieldset>
        <FormFieldset>
          <InputWrapper>
            <VisuallyHiddenLabel htmlFor="message">
              {translation.specialRequests}
            </VisuallyHiddenLabel>
            <InputTextarea
              placeholder={`${translation.specialRequests}`}
              name="message"
              id="message"
              value={values.message}
              onChange={handleChange}
            />
            <ScruffyTextareaBackground />
          </InputWrapper>
        </FormFieldset>
        <FormFieldset>
          <SubmitButton
            type="submit"
            disabled={showThankYou}
            value={
              values.matter === "catering" || values.matter === "event/meeting"
                ? translation.sendRequest
                : translation.sendRequest
            }
            onClick={handleSubmit}
          />
        </FormFieldset>
      </StyledForm>
    </FormContainer>
  )
}
