import React, { useContext } from "react"
import { IModalContext, ModalContext } from "../context/modalContext"
import styled from "styled-components"
import { SquareButton } from "../shared/StyleMixins"
import { useGetHashData } from "../hooks/useGetHashData"
import * as translations from "../utils/translations"
import { ILocaleContext, LocaleContext } from "../context/localeContext"

export const BookATableModal = () => {
  const { locale } = useContext(LocaleContext) as ILocaleContext
  const data = useGetHashData()
  const translation =
    locale === "en"
      ? translations.bookingModal.en
      : translations.bookingModal.sv

  const { toggleModal } = useContext(ModalContext) as IModalContext

  const filterOut = () => {
    if (locale === "en") {
      return (location: {
        node: { location: string; buttonOneLinksTo: string }
      }) => {
        return (
          !location.node.location.includes("-en") &&
          !location.node.location.includes("Takpark")
        )
      }
    }
    return (location: {
      node: { location: string; buttonOneLinksTo: string }
    }) => {
      return (
        !location.node.location.includes("-en") &&
        !location.node.location.includes("Rooftop")
      )
    }
  }

  const locations = data.allContentfulLocation.edges.filter(filterOut())

  const handleOnClick = () => {
    toggleModal()
  }

  // https://www.typescriptlang.org/docs/handbook/utility-types.html#recordkeys-type
  const locationMap: Record<
    string,
    { node: { location: string; buttonOneLinksTo: string } }
  > = {}

  return (
    <ModalWrapper>
      <Container>
        <CloseButton onClick={handleOnClick}></CloseButton>
        <HeadingContainer>
          <SubHeading>{translation.whereEat}</SubHeading>
          <Heading>{translation.choseRestaurant}</Heading>
        </HeadingContainer>
        <ButtonsWrapper>
          {locations.map((location, i) => {
            if (locationMap[location.node.location]) {
              return
            }
            locationMap[location.node.location] = location
            return (
              <StyledLink
                key={i}
                data-hash={location.node.buttonOneLinksTo}
                className="waiteraid-widget"
              >
                {location.node.location}
              </StyledLink>
            )
          })}
        </ButtonsWrapper>
      </Container>
    </ModalWrapper>
  )
}

const ModalWrapper = styled.div`
  background: var(--ud-light);
  outline: 200vw solid rgba(0, 0, 0, 0.5);
  width: calc(100% - (var(--site-main-gutter-mobile) * 2));
  max-width: 45.8rem;

  max-height: 45.1875rem;
  padding-bottom: 12rem;
  overflow: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
`

const CloseButton = styled.div`
  position: absolute;
  right: 2rem;
  top: 2rem;
  width: 2rem;
  height: 2rem;
  transition: ease-in-out 0.1s;

  &:hover {
    cursor: pointer;
    transform: scale(1.2);
  }

  &::before,
  &::after {
    position: absolute;
    left: 0.9375rem;
    content: " ";
    height: 2.5rem;
    width: 0.125rem;
    background-color: var(--ud-black);
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
`

const Container = styled.div`
  position: relative;

  padding: 0 1.25rem 1rem 1.25rem;
`

const HeadingContainer = styled.div`
  padding-top: 8.76rem;
  margin-bottom: 3.87rem;
  display: flex;
  align-items: center;
  flex-direction: column;
`
const SubHeading = styled.p``
const Heading = styled.h3``

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`
const StyledLink = styled.button`
  ${SquareButton};
  background: inherit;
  border: 1px solid var(--ud-black);
  width: 100%;
  margin: 0 1.25rem 1rem 1.25rem;

  &:hover {
    background: var(--ud-black);
    color: var(--ud-white);
  }
`
