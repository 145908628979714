import { Link } from "gatsby"
import React, { useContext } from "react"
import styled, { css } from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { PillButton } from "../shared/StyleMixins"
import { device } from "../shared/Device"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import { GatsbyImageType } from "../shared/ContentfulTypes"
import { ILocaleContext, LocaleContext } from "../context/localeContext"

const richTextOptions = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
      const url = node?.data?.target?.file?.url
      const name = node?.data?.target?.file?.fileName

      return (
        <a href={url} target="_blank">
          {name}
        </a>
      )
    },
    [INLINES.HYPERLINK]: (node: any) => {
      return (
        <a href={node?.data?.uri} target="_blank">
          {node.content[0].value}
        </a>
      )
    },
  },
}

type Props = {
  data: {
    buttonText: string
    buttonLinkTo: string
    heading: string
    fullHeight: boolean
    image: GatsbyImageType
    subHeading: string
    text: string
    textField: any
    slug: string
    fields: {
      newSlug: string
    }
  }
}

const NewsCard: React.FC<Props> = ({ data }) => {
  const {
    buttonText,
    buttonLinkTo,
    heading,
    fullHeight,
    image,
    subHeading,
    fields: { newSlug },
    textField,
    slug,
  } = data

  const { locale } = useContext(LocaleContext) as ILocaleContext
  const linksTo = buttonLinkTo
    ? buttonLinkTo
    : locale === "en"
    ? `/${newSlug}`
    : newSlug

  const richText = data.textField

  return (
    <>
      <Wrapper
        className={`${image ? "has-image" : ""} ${
          fullHeight ? "has-fullheight-image" : ""
        } slide-wrapper`}
      >
        {buttonText ? (
          <StyledLink to={`${linksTo.toLocaleLowerCase()}`}>
            {image && (
              <ImageWrapper fullHeight={fullHeight}>
                {image?.gatsbyImageData && (
                  <GatsbyImage
                    image={image?.gatsbyImageData}
                    alt={image?.description || ""}
                    className="news-image-wrapper"
                    imgClassName="news-image"
                    loading={"lazy"}
                    objectFit={"cover"}
                  />
                )}
              </ImageWrapper>
            )}
            <Content
              fullHeight={fullHeight}
              className={image ? "has-gradient" : ""}
            >
              <Heading className="h4">{heading}</Heading>
              <SubHeading className="h3">{subHeading}</SubHeading>
              <StyledText>
                {renderRichText(richText, richTextOptions)}
              </StyledText>
              {buttonText ? (
                <LinkButton to={`${linksTo}`}>{buttonText}</LinkButton>
              ) : null}
            </Content>
          </StyledLink>
        ) : (
          <>
            {image && (
              <ImageWrapper fullHeight={fullHeight}>
                {image?.gatsbyImageData && (
                  <GatsbyImage
                    image={image?.gatsbyImageData}
                    alt={image.description || ""}
                    className="news-image-wrapper"
                    imgClassName="news-image"
                    loading={"lazy"}
                    objectFit={"cover"}
                  />
                )}
              </ImageWrapper>
            )}
            <Content
              fullHeight={fullHeight}
              className={image ? "has-gradient" : ""}
            >
              <Heading className="h4">{heading}</Heading>
              <SubHeading className="h3">{subHeading}</SubHeading>
              <StyledText>
                {renderRichText(richText, richTextOptions)}
              </StyledText>
              {buttonText ? (
                <LinkButton to={`${linksTo}`}>{buttonText}</LinkButton>
              ) : null}
            </Content>
          </>
        )}
      </Wrapper>
    </>
  )
}

type StyledProps = {
  fullHeight: Boolean
}

const StyledLink = styled(Link)`
  color: inherit;
`

const LinkButton = styled(Link)`
  ${PillButton};
  position: relative;

  &:focus,
  &:hover,
  &:active {
    --button-background-color: var(--secondary-pink);
    --button-border-color: var(--button-background-color);
    --button-text-color: var(--ud-black);
  }
`

const Wrapper = styled.div`
  position: relative;
  flex: 0 0 auto;
  width: 83%;
  max-width: 19.625rem;
  background: var(--ud-blue);
  transition: transform 0.3s ease-in-out;
  margin-right: 0.875rem;

  &.has-image {
    background: var(--ud-white);
  }

  &.has-fullheight-image {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  &:nth-child(4n + 1) {
    &:focus,
    &:focus-within,
    &:hover {
      transform: rotate(-1deg);
    }
  }
  &:nth-child(4n + 2) {
    transform: rotate(-2deg);

    &:focus,
    &:focus-within,
    &:hover {
      transform: rotate(0deg);
    }
  }
  &:nth-child(4n + 3) {
    transform: rotate(1deg);

    &:focus,
    &:focus-within,
    &:hover {
      transform: rotate(0deg);
    }
  }
  &:nth-child(4n + 4) {
    transform: rotate(-1deg);

    &:focus,
    &:focus-within,
    &:hover {
      transform: rotate(0deg);
    }
  }
`

const ImageWrapper = styled.div<StyledProps>`
  ${(StyledProps) =>
    StyledProps.fullHeight
      ? css`
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        `
      : css`
          position: relative;
          width: 100%;
          height: 0;
          padding-bottom: 71.33757962%;
          overflow: hidden;
        `}

  @supports (aspect-ratio: 1.40178571 / 1) {
    ${(StyledProps) =>
      StyledProps.fullHeight
        ? css``
        : css`
            height: auto;
            padding-bottom: 0;
            aspect-ratio: 1.40178571 / 1;
          `}
  }

  .news-image-wrapper,
  .news-image {
    height: 100%;
  }
`

const Content = styled.div<StyledProps>`
  position: relative;
  padding: 1.25rem 0.9375rem;
  ${(StyledProps) =>
    StyledProps.fullHeight
      ? css`
          padding-top: calc(71.33757962% + 1.25rem);

          &.has-gradient {
            margin-top: 71.33757962%;
            padding-top: 3.25rem;
            color: var(--ud-white);

            &:before {
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              content: "";
              background: linear-gradient(
                rgba(0, 0, 0, 0),
                rgba(0, 0, 0, 0.8) 7rem
              );
            }
          }
        `
      : css``}
`
//NOTE Varför blir texten större när det finns en "läsmer" knapp?
const Heading = styled.h4`
  position: relative;
  font-size: 1.2rem;
  line-height: 1.16666667;
  margin-bottom: 0.3125rem;
`

const SubHeading = styled.h4`
  position: relative;
  margin-bottom: 0.9375rem;
`

const StyledText = styled.section`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  font-size: 0.875rem;
  line-height: 1.28571429;
  margin: 0 0 1.25rem 0;

  //This is needed for clamp to work in safari
  p {
    display: inline;
  }

  @media ${device.laptop} {
    min-height: 4.62rem;
  }
`
export default NewsCard
