import React from "react"
import styled from "styled-components"
import {
  ContentfulRichTextGatsbyReference,
  RenderRichTextData,
} from "gatsby-source-contentful/rich-text"
import { CenteredMaxContentWidth } from "../shared/StyleMixins"
import { device } from "../shared/Device"
import { RichText } from "../components/RichText"

export type RichTextProperties = {
  richTextSection: RenderRichTextData<ContentfulRichTextGatsbyReference>
}

type RichTextColumnsProps = {
  data: {
    richText: RichTextProperties[]
    heading: string
  }
}

export const RichTextColumns: React.FC<RichTextColumnsProps> = ({ data }) => {
  const { richText, heading } = data

  return (
    <StyledSection>
      <StyledHeading>{heading}</StyledHeading>
      <StyledRichTextContainer>
        {richText.map((richText: any, index) => {
          return <RichText key={index} data={richText} />
        })}
      </StyledRichTextContainer>
    </StyledSection>
  )
}

const StyledSection = styled.div`
  ${CenteredMaxContentWidth};
  padding-right: var(--site-main-gutter-mobile);
  padding-left: var(--site-main-gutter-mobile);

  @media ${device.laptop} {
    max-width: 68.75rem;
    padding: 0;
  }
`

const StyledHeading = styled.h3`
  max-width: 20rem;
  padding-left: 5rem;
  margin-top: 4.25rem;
  text-align: left;
  margin-bottom: 4.5rem;

  @media ${device.tabletM} {
    padding-left: 0;
  }

  @media ${device.laptop} {
    margin-top: 7.25rem;
    padding-left: 0;
  }
`

const StyledRichTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 5rem;
  margin: 0 auto;
  margin-bottom: 6.25rem;

  @media ${device.tabletM} {
    display: grid;
    grid-row-gap: 5rem;
    grid-column-gap: 7.85rem;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 6.25rem;
    padding-left: 0;
  }
  @media ${device.laptop} {
    display: grid;
    grid-row-gap: 5rem;
    grid-column-gap: 7.85rem;
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 6.25rem;
    padding-left: 0;
  }
`
//tes
