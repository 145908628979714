import React, { useRef, useState } from "react"
import styled from "styled-components"
import Pause_button from "../images/pause_btn.svg"
import { device } from "../shared/Device"
import ReactPlayer from "react-player/lazy"
import video from "../images/5050_subtitle_web.mp4"

type Props = {
  data: {
    __typename: string
    campaignVideo: any
  }
}

export const CampaignLandingPage: React.FC<Props> = ({ data }) => {
  const { campaignVideo } = data
  const [playing, setPlaying] = useState(true)

  const vidRef = useRef<HTMLVideoElement>(null)

  const handleClick = () => {
    if (vidRef.current) {
      setPlaying(!playing)
    }
  }
  return (
    <VideoWrapper onClick={handleClick}>
      <StyledVid>
        <ReactPlayer
          url={campaignVideo?.file?.url}
          loop={true}
          playsinline={true}
          playing={playing}
          width="100%"
          height="100%"
          volume={1}
          muted={true}
          fallback={<Pause_button />}
          /* @ts-ignore */
          ref={vidRef}
          controls={true}
        />
      </StyledVid>
    </VideoWrapper>
  )
}

const VideoWrapper = styled.div`
  position: relative;
  display: block;
  width: 100%;
  aspect-ratio: 100 / (640 / 360);
  max-width: 1800px;
  height: 100%;
  padding-bottom: 56.25%;
  cursor: pointer;
  margin-bottom: 5rem;

  .show_btn {
    margin: 0;
    padding: 0;
    opacity: 1;
  }

  &:hover .show_btn {
    opacity: 1;
  }

  @media ${device.laptop} {
    .show_btn {
      opacity: 0;
    }
  }

  @media (min-width: 1024px) {
    .video-wrapper {
      padding-bottom: 56.25%;
    }
  }

  @supports (aspect-ratio: 2 / 1) {
    .video-wrapper {
      height: auto;
      padding-bottom: 0;
      aspect-ratio: 16 / 9;
    }

    @media (min-width: 1024px) {
      .video-wrapper {
        aspect-ratio: 2 / 1;
      }
    }
  }
`

const StyledVid = styled.div`
  position: absolute;
  cursor: pointer;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
`
