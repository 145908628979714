import React, { useRef, useState } from "react"
import styled, { css } from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import {
  ContentfulRichText,
  FoodMenuProps,
  GatsbyImageType,
} from "../shared/ContentfulTypes"
import {
  CenteredMaxContentWidth,
  PillButton,
  SlantedWrapper,
} from "../shared/StyleMixins"
import { device } from "../shared/Device"
import { BLOCKS } from "@contentful/rich-text-types"

import { seperateRichText } from "../utils/seperateRichText"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { scrollToSection } from "../utils/createAnchorLink"
const richTextOptionsFoodMenu: any = {
  renderNode: {
    // Don't know what going on with children here.
    [BLOCKS.UL_LIST]: (node: ContentfulRichText, children: any[]) => {
      let isHeading = false
      return (
        <StyledUL>
          {children.map((li, i) => {
            if (
              typeof li?.props?.children[0]?.props?.children[0] === "string"
            ) {
              const [name, ingredients, price] =
                li?.props?.children[0]?.props?.children[0]?.split(":")
              isHeading = li?.props?.children[0]?.props?.children[0].includes(
                ":"
              )
                ? true
                : false

              return (
                <React.Fragment key={i}>
                  <StyledMenuDivision key={i}>
                    <StyledFoodWrapper>
                      <StyledFoodName isHeading={isHeading}>
                        {name}
                      </StyledFoodName>
                      <StyledFoodIngredients>
                        {ingredients}
                      </StyledFoodIngredients>
                    </StyledFoodWrapper>

                    <StyledFoodPrice>{price}</StyledFoodPrice>
                  </StyledMenuDivision>
                </React.Fragment>
              )
            }
          })}
        </StyledUL>
      )
    },
    [BLOCKS.PARAGRAPH]: (_: any, children: any) => (
      <StyledParagraph>{children}</StyledParagraph>
    ),
  },
}

export const FoodMenu: React.FC<FoodMenuProps> = ({ data }) => {
  const { heading = "", foodMenuImage = [], categories, typeOfMenu } = data
  const [{ heading: imgHeading = "", image = "" } = {}] = foodMenuImage || []
  const [activeCategory, setActiveCategory] = useState(categories[0].heading)
  let richTextOptions = typeOfMenu == "Catering" ? "" : richTextOptionsFoodMenu

  //creates a anchor for the menu section
  const sectionRef = useRef(null)
  scrollToSection(sectionRef, "#menu")
  //

  const [{ menu, servingHours }] = categories.filter((category) => {
    return category.heading === activeCategory
  })
  const cateringMenu = seperateRichText(menu)
  return (
    <StyledSectionWrapper ref={sectionRef} id={"menu"}>
      <StyledSectionContent>
        <ImageFoodWrapper>
          {data.foodMenuImage && image && (
            <GatsbyImage
              alt={imgHeading || "missing"}
              image={image.gatsbyImageData}
            />
          )}
        </ImageFoodWrapper>

        <Heading>{heading}</Heading>
        <FoodSelections>
          {categories.map((category, i) => {
            return (
              <StyledButton
                key={i}
                color={category.heading === activeCategory ? "" : "white"}
                onClick={() => setActiveCategory(category.heading)}
              >
                {category.heading}
              </StyledButton>
            )
          })}
        </FoodSelections>

        <ServingHours>{servingHours && servingHours}</ServingHours>

        <Menu typeOfMenu={typeOfMenu}>
          {typeOfMenu === "Food" &&
            menu &&
            renderRichText(menu, richTextOptions)}
          {typeOfMenu === "Catering" &&
            menu &&
            cateringMenu.map((item, index) => {
              return (
                <StyledCateringWrapper key={index}>
                  {item}
                </StyledCateringWrapper>
              )
            })}
        </Menu>
      </StyledSectionContent>
    </StyledSectionWrapper>
  )
}

type StyleProps = {
  color: string
}

type StyleTextProps = {
  isHeading: boolean
}
type StyledCatering = {
  typeOfMenu: string
}

const StyledParagraph = styled.p`
  margin-top: -4.5rem;
`

const StyledFoodWrapper = styled.div`
  flex: 1 1 auto;
  margin-right: 1rem;
`

const StyledMenuDivision = styled.div`
  --fontSize: 2.25rem;
  display: flex;
  margin: 15px 0px;
  position: relative;
  justify-content: space-between;
  span {
    display: block;
  }
`

const StyledFoodName = styled.span<StyleTextProps>`
  display: block;
  font-family: var(--font-heading);
  font-size: ${(StyleTextProps) =>
    StyleTextProps.isHeading ? "1.125rem" : "2.25rem"};
  line-height: 1.33333333;
  margin-bottom: 0.25rem;

  @media ${device.tabletM} {
    font-size: ${(StyleTextProps) =>
      StyleTextProps.isHeading ? "1.5rem" : "2.5rem"};
    margin-bottom: 0.375rem;
    min-width: 265px;
  }
`
const StyledFoodIngredients = styled.span`
  max-width: 19.56rem;
  margin-bottom: 1.56rem;
`
const StyledFoodPrice = styled.span`
  font-family: var(--font-heading);
  font-size: 1.125rem;
  line-height: 1.33333333;
  text-align: right;
  margin-bottom: 0.25rem;

  @media ${device.tabletM} {
    font-size: 1.5rem;
    margin-bottom: 0.375rem;
  }
`
const StyledSectionWrapper = styled.div`
  ${SlantedWrapper};
`
const StyledSectionContent = styled.div`
  ${CenteredMaxContentWidth};
`
const ImageFoodWrapper = styled.div`
  position: relative;
  transform: translate(-15%, -4rem) rotate(-1deg);
  width: 100%;
  max-width: 42rem;
  overflow: hidden;
  z-index: 1;

  @media ${device.desktopM} {
    transform: translate(
        calc(
          (
            (
                ((var(--big-content-max-width) - var(--content-max-width)) / 2) -
                  var(--site-main-gutter-laptop)
              ) * -1
          )
        ),
        -4rem
      )
      rotate(-1deg);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const Heading = styled.h2`
  display: flex;
  justify-content: center;
`

const ServingHours = styled.h1`
  font-size: 24px;
  text-align: center;
  margin-bottom: 3.5rem;
`

const FoodSelections = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  max-width: var(--content-max-width);
  margin: 0 auto 3rem auto;
  gap: 1rem;
`

const StyledButton = styled.button<StyleProps>`
  ${PillButton};
  cursor: pointer;
  --button-border-width: 1px;
  ${(Props) =>
    Props.color == "white"
      ? css`
          --button-background-color: var(--ud-white);
          --button-border-color: var(--ud-black);

          &:focus,
          &:hover {
            --button-background-color: var(--ud-black);
            --button-text-color: var(--ud-white);
          }
        `
      : css`
          --button-background-color: var(--ud-yellow);
          --button-border-color: var(--button-background-color);
        `}
`

const Menu = styled.div<StyledCatering>`
  padding-right: var(--site-main-gutter-mobile);
  padding-left: var(--site-main-gutter-mobile);
  align-items: flex-start;

  @media ${device.tabletM} {
    ${(StyledCatering) => {
      return StyledCatering.typeOfMenu == "Food"
        ? css`
            column-count: 2;
            column-gap: 1.5rem;
          `
        : css`
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            grid-column-gap: 1rem;
          `
    }};
    margin-right: 2rem;
  }

  @media ${device.laptop} {
    column-count: 2;
    column-gap: 12.125rem;
    padding-right: 0;
    padding-left: 0;

    ${(StyledCatering) => {
      return StyledCatering.typeOfMenu == "Food"
        ? css`
            column-count: 2;
            column-gap: 12.125rem;
          `
        : css`
            display: grid;
            grid-template-columns: repeat(2, 1fr);
          `
    }};
  }

  h3 {
    font-size: 36px;
    margin: 0;
  }

  h4 {
    font-size: 24px;
  }
`

const StyledUL = styled.ul`
  border: 1px solid white;
  break-inside: avoid;
  margin-bottom: 3.5rem;
  margin-top: 0;
  padding: 0;
`
//Styling for catering menu

const StyledCateringWrapper = styled.div`
  h3 {
    font-family: var(--font-heading);
    font-size: 26px;
    margin-bottom: 1.87rem;
    margin-top: 100px;
  }
  p {
    font-family: var(--font-text);
    line-height: 28px;
  }
  h4 {
    font-family: var(--font-bold);
    font-size: 14px;
    margin-bottom: 0.2rem;
  }
  h5 {
    margin-bottom: 0.2rem;
    font-size: 16px;
    line-height: 22px;
  }
`
