import React, { useState } from "react"
import styled, { css } from "styled-components"
import { device } from "../shared/Device"
import { qna } from "../sections/QandA"
import IconChevron from "../images/icon__chevron.svg"

export const QuestionAndAnswer: React.FC<qna> = ({ qna }) => {
  const { question, answer } = qna
  const [isOpen, setIsOpen] = useState(false)

  function toggleAnswer(e: any) {
    e.preventDefault()
    setIsOpen(!isOpen)
    let qnaHeader = e.currentTarget
    let qnaAnswer = qnaHeader.nextElementSibling
    let answerHeight = qnaAnswer.scrollHeight
    let answerNewHeight = qnaAnswer.clientHeight !== 0 ? 0 : answerHeight
    qnaAnswer.style.height = answerNewHeight + "px"
  }
  return (
    <>
      <DropDownContainer>
        <DropDownHeader onClick={(e) => toggleAnswer(e)}>
          <QuestionWrapper className="h5">{question || ""}</QuestionWrapper>
          <StyledIcon isOpen={isOpen}>
            <IconChevron />
          </StyledIcon>
        </DropDownHeader>
        <DropDownListContainer>
          <Answer>{answer.answer}</Answer>
        </DropDownListContainer>
      </DropDownContainer>
    </>
  )
}

type StyledProps = {
  isOpen: boolean
}

const DropDownContainer = styled.li`
  margin: 0 0 0.625rem 0;
  padding: 0;
`
const DropDownHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.625rem 0;
  cursor: pointer;
`
const QuestionWrapper = styled.h3`
  margin: 0 1rem 0 0;
`
const StyledIcon = styled.svg<StyledProps>`
  align-self: center;
  width: 1.25rem;
  height: 0.75rem;
  transition: 0.3s;
  transform: rotate(${(StyledProps) => (StyledProps.isOpen ? "180deg" : "0")});
`

const DropDownListContainer = styled.div`
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
  border-bottom: 2px solid var(--ud-black);
`

const Answer = styled.p`
  display: block;
  width: 100%;
  padding: 1.25rem 0 2.125rem 0;
  cursor: pointer;
`
