import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { GatsbyImageType } from "../shared/ContentfulTypes"
import { CenteredMaxContentWidth, SlantedWrapper } from "../shared/StyleMixins"
import { device } from "../shared/Device"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

const richTextOptions = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
      const url = node?.data?.target?.file?.url
      const name = node?.data?.target?.file?.fileName
      return (
        <a href={url} target="_blank" rel="noopener noreferrer">
          {name}
        </a>
      )
    },

    [INLINES.HYPERLINK]: (node: any) => {
      return (
        <a href={node?.data?.uri} target="_blank" rel="noopener noreferrer">
          {node.content[0].value}
        </a>
      )
    },
    [INLINES.ASSET_HYPERLINK]: (node: any) => {
      const url = node?.data?.target?.file?.url
      const name = node?.content[0].value
      return (
        <a href={url} target="_blank" rel="noopener noreferrer">
          {name}
        </a>
      )
    },
  },
}

type MediaBlocksType = {
  image?: GatsbyImageType
}

type Props = {
  data?: {
    heading?: string
    location?: string
    text?: { text?: string }
    mediaBlocks?: MediaBlocksType[]
    textField?: any
  }
}

export const PromoteNoCTA: React.FC<Props> = ({ data }) => {
  const { heading, location, mediaBlocks, textField } = data || {}
  const rawText = textField || ""

  let backgroundColor, textColor

  switch (location?.toLocaleLowerCase()) {
    case "sickla":
      backgroundColor = "--ud-orange"
      textColor = "--ud-black"
      break
    case "sveavägen":
      backgroundColor = "--ud-blue"
      textColor = "--ud-black"
      break
    default:
      backgroundColor = "--ud-white"
      textColor = "--ud-black"
  }

  const imageOneData = mediaBlocks?.[0]?.image?.gatsbyImageData
  const imageTwoData = mediaBlocks?.[1]?.image?.gatsbyImageData

  const imageOne = imageOneData ? getImage(imageOneData) : null
  const imageTwo = imageTwoData ? getImage(imageTwoData) : null

  return (
    <StyledSectionWrapper
      backgroundColor={backgroundColor}
      textColor={textColor}
    >
      <StyledSectionContent>
        {imageOne && (
          <ImageOneWrapper>
            <GatsbyImage image={imageOne} alt={"missing"} />
          </ImageOneWrapper>
        )}
        <Content>
          <Heading>{heading || ""}</Heading>
          {rawText ? renderRichText(rawText, richTextOptions) : null}
        </Content>
        {imageTwo && (
          <ImageTwoWrapper>
            <GatsbyImage image={imageTwo} alt={"missing"} />
          </ImageTwoWrapper>
        )}
      </StyledSectionContent>
    </StyledSectionWrapper>
  )
}

// Styled components remain unchanged

type StyledProps = {
  backgroundColor: string
  textColor: string
}
const StyledSectionWrapper = styled.div<StyledProps>`
  --background-color: var(${(StyledProps) => StyledProps.backgroundColor});
  --text-color: var(${(StyledProps) => StyledProps.textColor});
  ${SlantedWrapper};
  background: var(--background-color);
  color: var(--text-color);
  border: 1px solid red;
`

const StyledSectionContent = styled.div`
  ${CenteredMaxContentWidth};
  a {
    color: var(--hyperlink-blue);
    font-weight: bold;
    text-decoration: underline;
  }
`
const ImageOneWrapper = styled.div`
  position: relative;
  transform: translate(-15%, -4rem) rotate(-1deg);
  width: 100%;
  max-width: 45.375rem;
  overflow: hidden;
  z-index: 1;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
const Content = styled.div`
  margin: -2rem 0 0;
  padding: 0 var(--site-main-gutter-mobile);

  @media ${device.tabletM} {
    max-width: 40rem;
    margin: -2rem auto 0 auto;
  }

  @media ${device.laptop} {
    padding: 0;
  }

  &:last-child {
    padding-bottom: 3rem;
  }
`
const Heading = styled.h2`
  display: flex;
  justify-content: center;
  text-align: center;
`
const ImageTwoWrapper = styled.div`
  position: relative;
  transform: translate(15%, 4rem) rotate(2deg);
  width: 100%;
  max-width: 60.8125rem;
  margin-left: auto;
  z-index: 1;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
