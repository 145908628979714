import React from "react"
import styled from "styled-components"
import { CenteredMaxContentWidth } from "../shared/StyleMixins"
import { device } from "../shared/Device"

type Props = {
  data: {
    heading: string
    infoTextField: { infoTextField: string }
  }
}

export const InfoText: React.FC<Props> = ({ data }) => {
  const { heading, infoTextField } = data
  return (
    <StyledSection>
      <FlexContainer>
        <SideContainer>
          <Heading>{heading}</Heading>
        </SideContainer>
        <MainContainer>
          <Text>{infoTextField.infoTextField}</Text>
        </MainContainer>
      </FlexContainer>
    </StyledSection>
  )
}

const StyledSection = styled.div`
  ${CenteredMaxContentWidth};
  padding-right: var(--site-main-gutter-mobile);
  padding-left: var(--site-main-gutter-mobile);
  margin-bottom: 7rem;

  @media ${device.laptop} {
    max-width: 68.75rem;
    padding-right: 0;
    padding-left: 0;
    margin-bottom: 9rem;
  }
`
const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media ${device.laptop} {
    flex-direction: row;
  }
`

const SideContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  @media ${device.laptop} {
    flex: 0 0 22rem;
    flex-direction: column;
    align-items: flex-start;
    width: 22rem;
    margin: -0.8125rem 2rem 0 0;
  }

  @media ${device.laptopM} {
    flex: 0 0 26rem;
    width: 26rem;
  }
`

const Heading = styled.h2``

const MainContainer = styled.div`
  width: 100%;

  @media ${device.laptop} {
    max-width: 40.625rem;
    margin: 0 auto;
  }

  @media ${device.desktopM} {
    max-width: 46.125rem;
  }
`

const Text = styled.p`
  width: 100%;
`
