import React from "react"
import { GatsbyImageType } from "../shared/ContentfulTypes"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { CenteredFullContentWidth } from "../shared/StyleMixins"
import Sticker from "../components/Sticker"
import { device } from "../shared/Device"

type Props = {
  data: {
    imageOne: GatsbyImageType
    imageTwo: GatsbyImageType
    sticker: string
    oneOrTwoPhotos: string
  }
}
export const DoublePhotos: React.FC<Props> = ({ data }) => {
  const { imageOne, imageTwo, sticker, oneOrTwoPhotos } = data

  return (
    <SectionWrapper
      className={sticker && sticker !== "No sticker" ? "has-sticker" : ""}
    >
      {oneOrTwoPhotos ? (
        <SingleImageWrapper>
          <GatsbyImage
            image={imageOne?.gatsbyImageData}
            alt={imageOne?.description || "missing"}
            className={"single"}
          />
        </SingleImageWrapper>
      ) : (
        <TwoImagesWrapper>
          <GatsbyImage
            image={imageOne?.gatsbyImageData}
            alt={imageOne?.description || "missing"}
            className="image_one"
          />
          <GatsbyImage
            image={imageTwo?.gatsbyImageData}
            alt={imageTwo?.description || "missing"}
            className="image_two"
          />
        </TwoImagesWrapper>
      )}
      {sticker && sticker !== "No sticker" ? (
        <StickerWrapper>
          <Sticker sticker={sticker.toLowerCase()} />
        </StickerWrapper>
      ) : null}
    </SectionWrapper>
  )
}

const SectionWrapper = styled.div`
  position: relative;
  ${CenteredFullContentWidth}
  margin-top: 13.75rem;
  margin-bottom: 4rem;

  @supports (margin-top: clamp(1rem, 2rem, 3rem)) {
    margin-top: clamp(6.875rem, 5.2885rem + 7.0513vw, 13.75rem);
  }

  &.has-sticker {
    margin-top: 14rem;

    @supports (margin-top: clamp(1rem, 2rem, 3rem)) {
      margin-top: clamp(
        8rem,
        5.461538461538462rem + 11.282051282051283vw,
        19rem
      );
    }
  }

  @media ${device.tablet} {
    margin-bottom: 6rem;
  }

  @media ${device.tabletM} {
    margin-bottom: 10rem;
  }

  @media ${device.laptopL} {
    width: 100%;
    max-width: var(--content-max-width);
    margin-right: auto;
    margin-left: auto;
  }
`

const SingleImageWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-gap: 0;
  margin-top: 2rem;

  @media ${device.tabletM} {
    margin-top: 10rem;
  }

  .single {
    grid-area: 1 / 1 / 2 / 5;
    width: 100%;
    max-width: 67.5rem;
    aspect-ratio: 2.06976744 / 1;
    transform: rotate(2deg);

    @media ${device.tabletM} {
      grid-area: 1 / 2 / 2 / 5;
      aspect-ratio: 3.35031847 / 1;
    }
  }
`

const TwoImagesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-gap: 0;
  margin-top: 2rem;

  @media ${device.tabletM} {
    margin-top: 10rem;
  }

  .image_one {
    grid-area: 1 / 1 / 2 / 3;
    width: 100%;
    max-width: 39rem;
    aspect-ratio: 1.3956044 / 1;
    transform: translate(-25%, 40%) rotate(-2deg);
    z-index: 1;

    @media ${device.tabletM} {
      aspect-ratio: 2.02597403 / 1;
      transform: translate(-25%, 20%) rotate(-2deg);
      z-index: unset;
    }

    @media ${device.laptopL} {
      transform: translateY(20%) rotate(-2deg);
    }
  }
  .image_two {
    grid-area: 1 / 2 / 2 / 5;
    width: 100%;
    max-width: 67.5rem;
    aspect-ratio: 1.55913978 / 1;
    transform: rotate(2deg);

    @media ${device.tabletM} {
      aspect-ratio: 3.35031847 / 1;
    }
  }
`

const StickerWrapper = styled.div`
  position: absolute;
  width: 6.25rem;
  height: 6.25rem;
  top: -4rem;
  right: 3rem;
  transform: rotate(10deg);
  z-index: 99;
  pointer-events: none;

  @media ${device.tabletM} {
    width: 12rem;
    height: 12rem;
    top: -8rem;
  }

  @media ${device.laptop} {
    width: 17rem;
    height: 17rem;
    top: -11rem;
  }
`
