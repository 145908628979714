import React from "react"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import styled from "styled-components"
import { CenteredMaxContentWidth, SquareButton } from "../shared/StyleMixins"
import { device } from "../shared/Device"
import { Link } from "gatsby"

type Props = {
  data: {
    heading: string
    infoText: any
    buttonOne: string
    buttonTwo: string
    buttonThree: string
  }
}

export const Presentation: React.FC<Props> = ({ data }) => {
  const { heading, infoText, buttonOne, buttonTwo, buttonThree } = data

  let buttonsArray = [
    { text: buttonOne, link: "#contactus" },
    { text: buttonTwo, link: "#menu" },
    { text: buttonThree, link: "#" },
  ]
  return (
    <SectionWrapper>
      <SideContainer>
        <Heading>{heading}</Heading>
        <Buttons>
          {buttonsArray.map((button, index) => {
            return (
              button.text && (
                <StyledLink key={index} className="" to={button.link}>
                  {button.text}
                </StyledLink>
              )
            )
          })}
        </Buttons>
      </SideContainer>
      <MainContainer>{renderRichText(infoText)}</MainContainer>
    </SectionWrapper>
  )
}

const SectionWrapper = styled.div`
  ${CenteredMaxContentWidth};
  display: flex;
  flex-direction: column;
  margin: 3.3125rem auto 18rem auto;

  @media ${device.laptop} {
    flex-direction: row;
    justify-content: space-between;
    max-width: 68.75rem;
    margin-bottom: 11.25rem;
  }
`

const SideContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  @media ${device.laptop} {
    flex: 0 0 22rem;
    flex-direction: column;
    align-items: flex-start;
    width: 22rem;
    margin: -0.8125rem 2rem 0 0;
  }

  @media ${device.laptopM} {
    flex: 0 0 26rem;
    width: 26rem;
  }
`

const Heading = styled.h1`
  padding-right: var(--site-main-gutter-mobile);
  padding-left: var(--site-main-gutter-mobile);

  @media ${device.laptop} {
    padding-right: 0;
    padding-left: 0;
  }
`

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  /* max-width: 21.25rem; */
  white-space: nowrap;

  @media ${device.tablet} {
    max-width: 21.25rem;
  }

  &:not(:empty) {
    margin: 0 auto 3rem 0;
  }
`

const StyledLink = styled(Link)`
  ${SquareButton};
  --hover-rotate: 1deg;
  --button-color: var(--secondary-blue);
  background-color: var(--button-color);
  border-color: var(--button-color);

  margin-bottom: 1.4rem;
  width: 100%;
  transform: rotate(-2deg);
  transition: transform 0.35s ease-in-out;

  &:nth-child(2) {
    --button-color: var(--secondary-pink);
    --hover-rotate: -1deg;

    transform: rotate(2deg);
  }
  &:nth-child(3) {
    --button-color: var(--secondary-green);
  }

  &:focus,
  &:hover,
  &:active {
    --button-text-color: var(--ud-black);
    transform: rotate(var(--hover-rotate));
  }
`

const MainContainer = styled.div`
  width: 100%;
  padding-right: var(--site-main-gutter-mobile);
  padding-left: var(--site-main-gutter-mobile);

  @media ${device.laptop} {
    max-width: 40.625rem;
    margin: 0 auto;
    padding-right: 0;
    padding-left: 0;
  }

  @media ${device.desktopM} {
    max-width: 46.125rem;
  }

  a {
    transition: opacity 0.25s ease;

    &:focus,
    &:hover,
    &:active {
      opacity: 0.5;
    }
  }
`
