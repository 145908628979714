import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"
import { device } from "../shared/Device"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { GatsbyImageType } from "../shared/ContentfulTypes"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"

const richTextOptions = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
      const url = node?.data?.target?.file?.url
      const name = node?.data?.target?.file?.fileName

      return (
        <a href={url} target="_blank">
          {name}
        </a>
      )
    },
    [INLINES.HYPERLINK]: (node: any) => {
      return (
        <a href={node?.data?.uri} target="_blank">
          {node.content[0].value}
        </a>
      )
    },
    [INLINES.ASSET_HYPERLINK]: (node: any) => {
      const url = node?.data?.target?.file?.url
      const name = node?.content[0].value

      return (
        <a href={url} target="_blank">
          {name}
        </a>
      )
    },
  },
}

type Props = {
  data: {
    heading: string
    image: GatsbyImageType
    createdAt: string
    text: any
  }
}

export const InfoPage: React.FC<Props> = ({ data }) => {
  const { heading, image, createdAt, text } = data

  const trimedDate = createdAt.split("T")[0]
  return (
    <SectionWrapper>
      <StyledImage>
        <GatsbyImage
          image={image?.gatsbyImageData}
          alt={image?.description || "missing"}
          className="topImage"
        />
      </StyledImage>
      <TextContainer>
        <Heading>{heading}</Heading>
        <Text>{renderRichText(text, richTextOptions)}</Text>
      </TextContainer>
      <PageFooter>
        <PageFooterText>{trimedDate}</PageFooterText>
      </PageFooter>
    </SectionWrapper>
  )
}

const SectionWrapper = styled.div`
  position: relative;
  background: var(--ud-white);
  margin: 0 auto;

  a {
    color: var(--hyperlink-blue);
    font-weight: bold;
  }

  @media ${device.laptop} {
    max-width: 53rem;
  }
`
const StyledImage = styled.div`
  max-width: var(--hero-max-width);
  position: relative;

  .topImage {
    height: 0;
    padding-bottom: 49.25946633%;
    aspect-ratio: unset;

    @supports (aspect-ratio: 2.03 / 1) {
      height: auto;
      padding-bottom: 0;
      aspect-ratio: 2.03 / 1;
    }

    @media ${device.desktop} {
      height: 0;
      aspect-ratio: unset;
      padding-bottom: 43.49702073%;

      @supports (aspect-ratio: 2.299 / 1) {
        height: auto;
        padding-bottom: 0;
        aspect-ratio: 2.299 / 1;
      }
    }
  }
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8rem 1rem 4rem 1rem;

  @media ${device.laptop} {
    padding: 5.9rem 7rem 10rem 7rem;
  }
`
const Heading = styled.h3`
  text-align: center;
  margin-bottom: 2rem;

  @media ${device.tablet} {
    font-size: 3.5rem;
  }
`
const Text = styled.p`
  @media ${device.tablet} {
    font-size: 1.5rem;
  }

  @media ${device.laptop} {
    font-size: 1.12rem;
  }
`

const PageFooter = styled.div`
  display: flex;
  align-items: center;
  height: 4.75rem;
  background: var(--ud-beige);
  margin-bottom: 8.5rem;
`
const PageFooterText = styled.p`
  font-size: 0.87rem;
  padding: 0 1.31rem 0 1rem;

  @media ${device.laptop} {
    padding-left: 4rem;
  }

  @media ${device.tablet} {
    font-size: 1.3rem;
  }
`
