import React, { useState, useEffect, useContext } from "react"
import styled, { css } from "styled-components"
import EventCard from "../components/EventCard"
import { GatsbyImageType } from "../shared/ContentfulTypes"
import {
  CenteredMaxContentWidth,
  PillButton,
  SlantedWrapper,
} from "../shared/StyleMixins"
import { device } from "../shared/Device"
import { useEventsSv } from "../hooks/useEventsSv"
import { useEventsEn } from "../hooks/useEventsEn"
import { useThemeDaysSv } from "../hooks/useThemeDaysSv"
import { ILocaleContext, LocaleContext } from "../context/localeContext"
import * as translations from "../utils/translations"
import { useThemeDaysEn } from "../hooks/useThemeDaysEn"
import { MailChimp } from "../components/MailChimp"

export type Occasion = {
  heading: string
  location: string
  category: string
  eventMonth: string
  eventDay: string
  eventTime: string
  eventEndTime: string
  description: { description: string }
  eventImage: GatsbyImageType
  textField: any
  slug: string
  fields: {
    newSlug: string
  }
}

type CalendarProps = {
  data: {
    heading: string
    events: Occasion[]
    eventType: string
    locale: string
  }
  location: string
  pageInfo: {
    slug: string
  }
}

export const Calendar: React.FC<CalendarProps> = ({
  data: { heading, eventType },
  pageInfo: { slug },
}) => {
  const { locale } = useContext(LocaleContext) as ILocaleContext

  const {
    allContentfulEvent: { nodes: events },
  } =
    eventType == "Event/news" && locale === "sv"
      ? useEventsSv()
      : eventType == "Event/news" && locale === "en"
      ? useEventsEn()
      : eventType !== "Event/news" && locale === "sv"
      ? useThemeDaysSv()
      : useThemeDaysEn()

  const [selectedLocation, setSelectedLocation] = useState("alla")
  const [eventLocations, setEventLocations] = useState<string[]>(
    locale === "en" ? ["all"] : ["alla"]
  )
  const [readMoreBtn, setReadMoreBtn] = useState(false)
  const [displayNumber, setDisplayNumber] = useState(6)
  const [eventList, setEventList] = useState<Occasion[]>([])
  const [showFilterButtons, setShowFilterButtons] = useState(true)

  let at =
    locale === "en"
      ? `${translations.div.pa.en.pa}`
      : `${translations.div.pa.se.pa}`

  const locationInHeading =
    selectedLocation == "alla" ? "" : `${at} ${selectedLocation}`
  useEffect(() => {
    switch (slug) {
      case "abonnemang":
        setShowFilterButtons(false)
        break
      case "hotellet":
        setSelectedLocation("sveavägen/takpark")
        break
      case "sveavagen":
        setSelectedLocation("sveavägen/takpark")
        break
      case "nytorget":
        setSelectedLocation("nytorget/postbar")
        break
      case "sickla":
        setSelectedLocation("sickla")
        break
      case "hagastaden":
        setSelectedLocation("hagastaden")
        break
      default:
        setSelectedLocation("alla")
        break
    }
  }, [])

  const filterEvents = () => {
    let eventArray = []
    if (selectedLocation === "alla") {
      eventArray = [...events]
    } else {
      let filtered = events.filter(
        (event: Occasion) => event.location?.toLowerCase() === selectedLocation
      )

      eventArray = [...filtered]
    }

    // return filterCategories(eventArray)
    if (eventArray.length > displayNumber || eventArray.length === 0) {
      setReadMoreBtn(true)
    } else {
      setReadMoreBtn(false)
    }

    return eventArray
  }

  useEffect(() => {
    setEventList(filterEvents())
  }, [selectedLocation, locale])

  //For location buttons
  useEffect(() => {
    events.map((event: Occasion) => {
      if (eventLocations.includes(event.location) || !event.location) {
        return
      } else {
        setEventLocations([...eventLocations, event.location])
      }
    })
  }, [eventLocations])

  return (
    <StyledSectionWrapper id="events">
      {eventList?.length === 0 ? (
        <StyledSectionContent>
          <StyledHeader>
            {locale === "sv"
              ? "Missa inte kommande event!"
              : "DON'T MISS UPCOMING EVENTS!"}
          </StyledHeader>
          <MailChimp positionOnSite={"footer"} />
        </StyledSectionContent>
      ) : (
        <StyledSectionContent>
          <StyledHeader>{`${heading} ${locationInHeading}`}</StyledHeader>

          <Locations>
            {showFilterButtons
              ? eventLocations.map((location, index) => {
                  return (
                    <StyledButton
                      onClick={() =>
                        setSelectedLocation(location?.toLowerCase())
                      }
                      key={index}
                      activeButton={
                        location?.toLowerCase() === selectedLocation
                          ? true
                          : false
                      }
                    >
                      {location}
                    </StyledButton>
                  )
                })
              : null}
          </Locations>
          <EventCardsWrapper>
            {eventList?.length > 0
              ? eventList?.map((event, i) => {
                  return (
                    i < displayNumber && <EventCard index={i} event={event} />
                  )
                })
              : events.map((event: Occasion, i: number) => {
                  return (
                    i < displayNumber && <EventCard index={i} event={event} />
                  )
                })}
          </EventCardsWrapper>
          {readMoreBtn ? (
            <LoadMore onClick={() => setDisplayNumber(displayNumber + 6)}>
              <StyledReadMoreButton>Ladda fler</StyledReadMoreButton>
            </LoadMore>
          ) : null}
        </StyledSectionContent>
      )}
    </StyledSectionWrapper>
  )
}

type StyledButtonProps = {
  activeButton: boolean
}

const StyledSectionWrapper = styled.div`
  ${SlantedWrapper};
  scroll-margin-top: 7.5rem;
`

const StyledSectionContent = styled.div`
  ${CenteredMaxContentWidth};
  padding: 3rem 0;
`

const StyledHeader = styled.h2`
  text-align: center;
`

const Locations = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.25rem;
  margin-bottom: 4rem;
`

const StyledButton = styled.button<StyledButtonProps>`
  ${PillButton};
  --button-border-width: 1px;
  ${(Props) =>
    !Props.activeButton
      ? css`
          --button-background-color: var(--ud-white);
          --button-border-color: var(--ud-black);
        `
      : css`
          --button-background-color: var(--ud-yellow);
          --button-border-color: var(--button-background-color);
        `}
  &:hover {
    color: var(--ud-white);
  }
  &:focus,
  &:active {
    --button-background-color: var(--ud-yellow);
    --button-border-color: var(--button-background-color);
    color: var(--ud-black);
  }
  @media ${device.laptop} {
    margin-top: 5rem;
  }
`

const StyledReadMoreButton = styled.button`
  ${PillButton};
  --button-background-color: var(--ud-white);
  --button-border-color: var(--ud-black);
  --button-border-width: 1px;
  margin-top: 3.15rem;
  &:focus {
    --button-background-color: var(--ud-white);
    --button-border-color: var(--ud-black);
    --button-text-color: var(--ud-black);
    outline: none;
  }
  &:hover {
    --button-background-color: var(--ud-black);
    --button-border-color: var(--button-background-color);
    --button-text-color: var(--ud-white);
    outline: none;
  }
`

const EventCardsWrapper = styled.div`
  @media ${device.laptop} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
  }
`

const LoadMore = styled.div`
  display: flex;
  justify-content: center;
`
