import React from "react"
import { GatsbyImageType } from "../shared/ContentfulTypes"
import styled from "styled-components"
import { CenteredMaxContentWidth } from "../shared/StyleMixins"
import { device } from "../shared/Device"

import { FlippingCard } from "../components/FlippingCard"

export type TurningCardTypes = {
  headingOnTheBack: string
  headingOnTheFront: string
  linkTo: string
  textOnTheBack: string
  cardImage: GatsbyImageType
  backgroundColor: string
}

export type CardTypes = {
  headingOnTheBack: string
  headingOnTheFront: string
  linkTo: string
  textOnTheBack: string
  cardImage: GatsbyImageType
  backgroundColor: string
}

type Props = {
  data: {
    turningCards: TurningCardTypes[]
  }
}

export const TurningImages: React.FC<Props> = ({ data }) => {
  const { turningCards } = data

  return (
    <SectionWrapper>
      <FlexContainer>
        {turningCards.map((card, index) => {
          return <FlippingCard key={index} card={card} />
        })}
      </FlexContainer>
    </SectionWrapper>
  )
}

const SectionWrapper = styled.div`
  ${CenteredMaxContentWidth};
  max-width: var(--hero-max-width);
`

const FlexContainer = styled.div`
  display: grid;
  grid-gap: 0.625rem;

  @media ${device.tablet} {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.25rem;
  }

  @media ${device.laptopM} {
    grid-template-columns: repeat(4, 1fr);
  }
`
