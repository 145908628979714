import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"
import { device } from "../shared/Device"
import Sticker from "../components/Sticker"
import {
  CenteredMaxContentWidth,
  SlantedWrapperReverse,
} from "../shared/StyleMixins"
import { GatsbyImageType } from "../shared/ContentfulTypes"

type Icons = {
  image: GatsbyImageType
  heading: string
}

type Props = {
  data: {
    heading: string
    sticker: string
    icons: Icons[]
  }
}

export const Closeby: React.FC<Props> = ({ data }) => {
  const { heading, sticker, icons } = data

  return (
    <SectionWrapper
      className={sticker && sticker !== "No sticker" ? "has-sticker" : ""}
    >
      <SectionContent>
        <Heading>{heading}</Heading>
        <Icons>
          {icons.map((icon, index) => {
            return (
              <React.Fragment key={index}>
                <FlexDiv>
                  <IconWrapper>
                    <GatsbyImage
                      image={icon?.image?.gatsbyImageData}
                      alt={icon.heading || ""}
                      loading={"lazy"}
                    />
                  </IconWrapper>
                  <IconHeading>{icon.heading}</IconHeading>
                </FlexDiv>
              </React.Fragment>
            )
          })}
        </Icons>
        {sticker && sticker !== "No sticker" ? (
          <StickerWrapper>
            <Sticker sticker={sticker.toLowerCase()} />
          </StickerWrapper>
        ) : null}
      </SectionContent>
    </SectionWrapper>
  )
}

const SectionContent = styled.div`
  ${CenteredMaxContentWidth};
  position: relative;
  padding: 3rem 0;
`

const SectionWrapper = styled.div`
  ${SlantedWrapperReverse};

  &.has-sticker {
    @supports (margin-top: clamp(1rem, 2rem, 3rem)) {
      margin-top: clamp(11rem, 10.30769rem + 3.0769vw, 14rem);
    }

    ${SectionContent} {
      padding: 6rem 0 3rem;

      @media ${device.laptop} {
        padding: 7.5rem 0 3rem;
      }
    }
  }
`

const Heading = styled.h2`
  display: flex;
  justify-content: center;
  margin-bottom: 0;

  @media ${device.mobileS} {
    text-align: center;
  }
`

const Icons = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0 auto;

  @media ${device.mobileM} {
    justify-content: center;
  }

  @media ${device.tabletM} {
    justify-content: center;
  }

  @media ${device.laptopM} {
    flex-wrap: nowrap;
  }
`

const FlexDiv = styled.div`
  flex: 0 0 50%;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 2.375rem 3.12rem 0 3.12rem;

  @media ${device.mobileL} {
    flex: 0 0 33.333333333%;
    width: 33.333333333%;
  }

  @media ${device.tablet} {
    flex: 0 0 25%;
    width: 25%;
  }

  @media ${device.tabletM} {
    flex: 0 0 20%;
    width: 20%;
  }

  @media ${device.laptop} {
    flex: 0 0 16.66666667%;
    width: 16.66666667%;
    margin: 2.375rem 1.5rem 0 1.5rem;
  }

  @media ${device.laptopM} {
    flex: 0 0 14.28571429%;
    width: 14.28571429%;
  }
`

const IconWrapper = styled.div`
  text-align: center;
  width: 3.75rem;
`

const IconHeading = styled.p`
  text-align: center;
  margin-top: 1rem;
`

const StickerWrapper = styled.div`
  position: absolute;
  width: 12.5rem;
  height: 12.5rem;
  top: -8.125rem;
  right: 0;
  transform: rotate(10deg);
  z-index: 99;
  pointer-events: none;

  @media ${device.laptop} {
    width: 17rem;
    height: 17rem;
    top: -11rem;
  }
`
