import React, { RefObject, useEffect } from "react"

export const scrollToSection = (
  sectionRef: RefObject<HTMLDivElement>,
  hash?: string | null,
  heading?: string | null,
  setSectionID?: (id: string) => void
) => {
  let sectionsID = ""

  if (heading === "Julmiddag på Sveavägen") {
    sectionsID = "julmiddag"
  } else if (heading === "Jul hos dig") {
    sectionsID = "bestall-julmat"
  }

  if (setSectionID && sectionsID !== "") {
    setSectionID(sectionsID)
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (
        window.location.hash === `${hash || sectionsID}` &&
        sectionRef.current
      ) {
        const y =
          sectionRef.current.getBoundingClientRect().top + window.scrollY - 100
        window.scrollTo({ top: y, behavior: "smooth" })
      }
    }, 1000)

    return () => clearTimeout(timer)
  }, [])
}
