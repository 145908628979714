import React from "react"
import styled from "styled-components"
import { device } from "../shared/Device"
import { CenteredMaxContentWidth } from "../shared/StyleMixins"
import RoomCard from "../components/RoomCard"
import { GatsbyImageType } from "../shared/ContentfulTypes"

export type RoomType = {
  roomType: string
  roomDescription: { roomDescription: string }
  button: string
  images: { image: GatsbyImageType }[]
  icons: [{ fullSize: boolean; heading: string; image: GatsbyImageType }]
}

type Props = {
  data: {
    heading: string
    infoText: { infoText: string }
    CarouselImageAndInfo: RoomType[]
  }
}

export const HotelRooms: React.FC<Props> = ({ data }) => {
  const { heading, infoText, CarouselImageAndInfo } = data

  let oneFlexContainer = infoText == null ? true : false

  return (
    <>
      <SectionWrapper>
        <SectionContent>
          <GeneralInfoWrapper oneFlexContainer={oneFlexContainer}>
            <GeneralHeading>{heading}</GeneralHeading>
            {infoText ? (
              <GeneralInfo>{infoText?.infoText || ""}</GeneralInfo>
            ) : null}
          </GeneralInfoWrapper>
          <RoomsWrapper>
            {CarouselImageAndInfo.map((room, index) => {
              return <RoomCard key={index} room={room} />
            })}
          </RoomsWrapper>
        </SectionContent>
      </SectionWrapper>
    </>
  )
}

type StyledProps = {
  oneFlexContainer: boolean
}

const SectionWrapper = styled.div`
  margin: 5rem 0 6rem 0;

  @media ${device.laptop} {
    margin-top: 8.75rem;
  }
`

const SectionContent = styled.div`
  ${CenteredMaxContentWidth};
  position: relative;
`

const GeneralInfoWrapper = styled.div<StyledProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 45.5rem;
  margin: 0 auto 3.875rem auto;

  @media ${device.laptopM} {
    flex-direction: row;
    justify-content: space-between;
    max-width: 70rem;
    margin: 0 auto 7.75rem auto;
  }

  @media ${device.tablet} {
    justify-content: ${(StyledProps) =>
      StyledProps.oneFlexContainer ? "center" : "space-between"};
    text-align: ${(StyledPops) =>
      StyledPops.oneFlexContainer ? "center" : "left"};
  }
`

const GeneralHeading = styled.h2``

const GeneralInfo = styled.p`
  @media ${device.laptopM} {
    flex: 0 0 59.82142857%;
    width: 59.82142857%;
  }
`

const RoomsWrapper = styled.div``
