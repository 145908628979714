import React, { useRef, useState, useEffect } from "react"
import styled from "styled-components"
import { device } from "../shared/Device"
import { GatsbyImage } from "gatsby-plugin-image"
import {
  PillButton,
  StepButton,
  CenteredMaxContentWidth,
} from "../shared/StyleMixins"
import IconChevron from "../images/icon__chevron.svg"
import { RoomType } from "../sections/HotelRooms"

type Props = {
  room: RoomType
}

const RoomCard: React.FC<Props> = ({ room }) => {
  const { roomType, roomDescription, button, images, icons } = room
  const movingWrapper = useRef<HTMLDivElement>(null)
  const contentWrapperRef = useRef<HTMLDivElement>(null)
  const [direction, setDirection] = useState("")
  const [moveDistance, setMoveDistance] = useState(0)
  const [counter, setCounter] = useState(0)
  const [limiterForward, setLimiterForward] = useState<boolean>(false)
  const [limiterBackward, setLimiterBackward] = useState<boolean>(false)
  let clientWidth = contentWrapperRef.current?.clientWidth || 670
  const numberOfImages = images.length - 1

  const moveContainerBy = (direction: "forward" | "back") => {
    setDirection(direction == "forward" ? "forward" : "backward")

    direction === "forward"
      ? setMoveDistance(moveDistance - clientWidth)
      : direction === "back"
      ? setMoveDistance(moveDistance + clientWidth)
      : null
  }

  useEffect(() => {
    counter >= numberOfImages
      ? setLimiterForward(true)
      : setLimiterForward(false)

    counter == 0 ? setLimiterBackward(true) : setLimiterBackward(false)
  }, [counter])

  return (
    <Wrapper>
      <InfoWrapper>
        <InfoHeading>{roomType}</InfoHeading>
        <InfoDescription>{roomDescription.roomDescription}</InfoDescription>
      </InfoWrapper>
      <StepButtonsWrapper>
        <Container>
          <StepButtonsButtonBackward
            onClick={() => {
              moveContainerBy("back")
              setCounter(counter - 1)
            }}
            limiterBackward={limiterBackward}
          >
            <StyledIcon>
              <IconChevron />
            </StyledIcon>
          </StepButtonsButtonBackward>
          <StepButtonsButtonForward
            onClick={() => {
              moveContainerBy("forward")
              setCounter(counter + 1)
            }}
            limiterForward={limiterForward}
          >
            <StyledIcon>
              <IconChevron />
            </StyledIcon>
          </StepButtonsButtonForward>
          <ImageWrapper
            movingDirection={direction}
            moveDistance={moveDistance}
            ref={movingWrapper}
          >
            {images.map((image, index) => {
              return (
                <ContentWrapper key={index} ref={contentWrapperRef}>
                  <GatsbyImage
                    image={image?.image?.gatsbyImageData}
                    alt={"missing"}
                    className={"image"}
                  />
                </ContentWrapper>
              )
            })}
          </ImageWrapper>
        </Container>
      </StepButtonsWrapper>
    </Wrapper>
  )
}

type StyledProps = {
  movingDirection?: string
  moveDistance?: number
  limiterForward?: boolean
  limiterBackward?: boolean
}

const Wrapper = styled.div`
  ${CenteredMaxContentWidth};
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  max-width: 45.5rem;
  margin-bottom: 1.25rem;
  background: var(--ud-white);

  @media ${device.laptopM} {
    flex-direction: row;
    justify-content: center;
    max-width: none;
  }
`

const InfoWrapper = styled.div`
  width: 100%;
  background-color: var(--ud-white);
  padding: 1.25rem 2rem;

  @media ${device.tabletM} {
    padding: 2rem 2.5rem;
  }

  @media ${device.laptopM} {
    width: 49.24242424%;
  }

  @media ${device.laptopL} {
    padding: 3rem 3.625rem;
  }
`

const InfoHeading = styled.h3``

const InfoDescription = styled.p``

const IconsWrapper = styled.div`
  display: flex;
`
const Icon = styled.div`
  :nth-child(n + 2) {
    margin-left: 4.5rem;
  }
`
const IconHeading = styled.p``

const StyledButton = styled.button`
  ${PillButton};
  width: 100%;
  margin: 2rem 0 0 0;

  @media ${device.tablet} {
    width: auto;
  }

  &:focus,
  &:hover,
  &:active {
    --button-background-color: var(--ud-black);
    --button-border-color: var(--button-background-color);
    --button-text-color: var(--ud-white);
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow: hidden;
`

const ImageWrapper = styled.div<StyledProps>`
  display: flex;
  flex-shrink: 0;
  transition: 1s;
  width: 100%;
  height: 0;
  padding-bottom: 61.64179104%;

  @supports (aspect-ratio: 1.62227603 / 1) {
    height: auto;
    padding-bottom: 0;
    aspect-ratio: 1.62227603 / 1;
  }

  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  ${(StyledProps) =>
    StyledProps.movingDirection == "forward"
      ? { transform: `translateX(${StyledProps.moveDistance}px)` }
      : StyledProps.movingDirection == "backward"
      ? { transform: `translateX(${StyledProps.moveDistance}px)` }
      : ""}
`

const ContentWrapper = styled.div`
  width: 100%;
  min-width: 100%;
`

const StepButtonsWrapper = styled.div`
  position: relative;
  width: 100%;

  @media ${device.laptopM} {
    width: 50.75757576%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-self: flex-start;
  }
`

const StepButtonsButtonForward = styled.button<StyledProps>`
  ${PillButton};
  ${StepButton};
  position: absolute;
  right: 0;
  top: 50%;
  z-index: 99;
  display: ${(StyledProps) => (StyledProps.limiterForward ? "none" : "flex")};
  transform: translate(0.625rem, -50%) rotate(-88deg);
`

const StepButtonsButtonBackward = styled.button<StyledProps>`
  ${PillButton};
  ${StepButton};
  position: absolute;
  left: 0;
  top: 50%;
  z-index: 99;
  display: ${(StyledProps) => (StyledProps.limiterBackward ? "none" : "flex")};
  transform: translate(-0.625rem, -50%) rotate(88deg);
`

const StyledIcon = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`

export default RoomCard
