import React, { useContext } from "react"
import styled from "styled-components"
import ReactMarkdown from "react-markdown"
import { CenteredMaxContentWidth } from "../shared/StyleMixins"
import { device } from "../shared/Device"
import { useMapLocations } from "../hooks/useMapLocation"
import { useMapLocationsEn } from "../hooks/useMapLocationEn"
import Map from "../components/Map"
import { Link } from "gatsby"
import { ILocaleContext, LocaleContext } from "../context/localeContext"
import * as translations from "../utils/translations"

type Props = {
  data: {
    addressHeading: string
    contactHeading: string
    mainHeading: string
    phoneNumber: string
    addressInformation: { addressInformation: string }
    contactInformation: { contactInformation: string }
  }
  location: string
}

export const FindUs: React.FC<Props> = ({ data, location }) => {
  const { mainHeading, phoneNumber } = data
  const { locale } = useContext(LocaleContext) as ILocaleContext

  const {
    allContentfulLocation: { nodes },
  } = locale === "en" ? useMapLocationsEn() : useMapLocations()

  if (location) {
    const [mapLocation] = nodes.filter((mapData) => {
      return mapData.location === location
    })

    const hotelNode = nodes.filter((node) => {
      node.location === "Hotell" && location === "Hotell"
    })

    const aboutUsSlug =
      locale === "en"
        ? translations.menu.omoss.en.slug
        : translations.menu.omoss.se.slug

    const contactHeading = mapLocation?.contactHeading
    const addressHeading = mapLocation?.addressHeading
    const hotelHeading = mapLocation?.hotelHeading
    const hotelInformation = mapLocation?.hotelInformation?.hotelInformation
    const addressInformation =
      mapLocation?.addressInformation?.addressInformation
    const contactInformation =
      mapLocation?.contactInformation?.contactInformation

    return (
      <StyledSection id="findus">
        {mainHeading && <Title>{mainHeading}</Title>}
        {(contactInformation || addressInformation) && (
          <ContentWrapper>
            <ContactWrapper>
              {contactHeading && <Heading>{contactHeading}</Heading>}
              <PhoneNumber>
                <p>
                  <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
                </p>
                <StyledMailLink to="/om-oss">Maila oss</StyledMailLink>
              </PhoneNumber>
            </ContactWrapper>
            {hotelHeading && (
              <HotelContactHeading>
                <Heading>{hotelHeading}</Heading>
                {<ReactMarkdown children={hotelInformation} />}
              </HotelContactHeading>
            )}
            <AddressInfoWrapper>
              {addressHeading && <Heading>{addressHeading}</Heading>}
              {addressInformation && (
                <ReactMarkdown children={addressInformation} />
              )}
            </AddressInfoWrapper>
          </ContentWrapper>
        )}
        <Map locations={hotelNode.length === 0 ? nodes : hotelNode} />
      </StyledSection>
    )
  }
  return null
}

const StyledSection = styled.div`
  ${CenteredMaxContentWidth};
  margin-top: 4rem;
  scroll-margin-top: 7.5rem;

  @media ${device.laptop} {
    margin-top: 4rem;
  }
`
const Heading = styled.h3``

const Title = styled.h2`
  text-align: center;

  @media ${device.laptop} {
    margin-top: 10rem;
  }
`

const ContentWrapper = styled.div`
  text-align: center;
  max-width: 50.625rem;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin: 0 auto 4rem auto;

  @media ${device.tablet} {
    flex-direction: row;
  }
`
const HotelContactHeading = styled.div`
  margin: 0 0 3rem 0;

  @media ${device.tablet} {
    margin: 0;
    text-align: left;
  }

  p {
    a {
      text-decoration: underline;
      text-underline-offset: 0.375rem;
      transition: color 0.25s ease;

      &:focus,
      &:hover,
      &:active {
        color: var(--ud-bronze);
      }
    }
  }
`

const ContactWrapper = styled.div`
  margin: 0 0 3rem 0;

  @media ${device.tablet} {
    margin: 0;
    text-align: left;
  }

  p {
    a {
      text-decoration: underline;
      text-underline-offset: 0.375rem;
      transition: color 0.25s ease;

      &:focus,
      &:hover,
      &:active {
        color: var(--ud-bronze);
      }
    }
  }
`
const StyledMailLink = styled(Link)`
  text-decoration: underline;
  text-underline-offset: 0.4rem;
`

const PhoneNumber = styled.div`
  margin-top: 1rem;
  text-decoration: underline;
  text-underline-offset: 0.4rem;
`

const AddressInfoWrapper = styled.div`
  @media ${device.tablet} {
    margin: 0;
    text-align: left;
  }
`
