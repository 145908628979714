import { graphql, useStaticQuery } from "gatsby"

export const useEventsEn = () => {
  const contentfulEvents = useStaticQuery(graphql`
    {
      allContentfulEvent(
        filter: {
          themeDay: { eq: false }
          node_locale: { eq: "en" }
          title: { ne: "DO NOT DELETE" }
        }
        sort: { fields: eventDate, order: ASC }
      ) {
        nodes {
          heading
          slug
          location
          # description {
          #   description
          # }
          textField {
            raw
          }
          fields {
            newSlug
          }
          eventMonth: eventDate(formatString: "MMM", locale: "sv")
          eventDay: eventDate(formatString: "DD")
          eventTime: eventDate(formatString: "HH:mm")
          eventEndTime: eventEndDate(formatString: "HH:mm")
          category
          eventImage {
            gatsbyImageData
            description
          }
        }
      }
    }
  `)

  return contentfulEvents
}
