import React, { useState, useEffect } from "react"
import ReactMarkdown from "react-markdown"
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet"
import { device } from "../shared/Device"
import { CenteredMaxContentWidth } from "../shared/StyleMixins"
import styled from "styled-components"
import L from "leaflet"
import axios from "axios"
import carIcon from "../../static/map-markers/map-marker__urban-deli-pa-vag.svg"
import { ListFormat } from "typescript"

type Props = {
  data: {
    heading: string
  }
}

export const UDonTheRoadMap: React.FC<Props> = ({ data }) => {
  const { heading } = data
  const [stops, setStops] = useState<any>([])

  function getIcon(iconUrl: string, iconWidth: number, iconHeight: number) {
    return L.icon({
      iconUrl: iconUrl,
      iconSize: [iconWidth, iconHeight],
      iconAnchor: [iconWidth / 2, iconHeight],
      popupAnchor: [0, -iconHeight],
    })
  }

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios(
        "https://prd-euw-pavag-urbandeli-default-rtdb.europe-west1.firebasedatabase.app/stops.json"
      )
      const stopsArray = Object.values(response.data)
      setStops(stopsArray)
    }
    fetchData()
  }, [])

  // useEffect(() => {
  //   // console.log(stops.length)
  // }, [stops])

  /* KANSKE KAN KOLLA PÅ https://stackoverflow.com/questions/42835692/react-leaflet-add-markers-dynamically
  ELLER LIKNANDE FÖR TIPS OM HUR MAN LÄGGER IN MARKERS DYNAMISKT */

  if (typeof window !== "undefined" && stops.length) {
    return (
      <SectionWrapper id="findus">
        {heading && <Title>{heading}</Title>}

        <MapSection>
          <MapContainer
            zoomControl={true}
            attributionControl={false}
            center={[59.327485332390026, 18.098329575117997]}
            zoom={12}
            scrollWheelZoom={false}
          >
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            {stops.map((stop: any, index: number) => {
              return (
                <Marker
                  key={index}
                  riseOnHover={true}
                  position={[stop.position_lat, stop.position_lng]}
                  icon={getIcon(carIcon, 39, 24)}
                >
                  {stop.city && (
                    <Popup
                      key={index}
                      keepInView={true}
                      maxWidth={416}
                      closeButton={false}
                      position={[stop.position_lat, stop.position_lng]}
                    >
                      <PopupContent key={index}>
                        <h3>{stop.city}</h3>
                        {stop.postal_address && (
                          <ReactMarkdown children={stop.postal_address} />
                        )}
                        {stop.delivery_times &&
                          stop.delivery_times.length > 0 && (
                            <ul key={index}>
                              {stop.delivery_times.map(
                                (d: any, index: number) => (
                                  <li key={index}>
                                    <span className="deliveryDate">
                                      {d.requested_delivery_date}
                                    </span>
                                    {d.timewindow_start} - {d.timewindow_end}
                                  </li>
                                )
                              )}
                            </ul>
                          )}
                        {stop.driver_note_1 && (
                          <ReactMarkdown
                            key={index}
                            children={stop.driver_note_1}
                          />
                        )}
                      </PopupContent>
                    </Popup>
                  )}
                </Marker>
              )
            })}
          </MapContainer>
        </MapSection>
      </SectionWrapper>
    )
  }
  return null
}

const SectionWrapper = styled.div`
  ${CenteredMaxContentWidth};
  margin-top: 4rem;
  scroll-margin-top: 7.5rem;

  @media ${device.laptop} {
    margin-top: 8rem;
  }
`

const Title = styled.h2`
  text-align: center;
`

const MapSection = styled.div`
  width: 100%;
  height: 65vh;
  overflow: hidden;

  @media ${device.tabletM} {
    height: 40rem;
  }

  .leaflet-container {
    width: 100%;
    height: 100%;
    z-index: 1;

    a {
      color: var(--ud-black);
    }

    .leaflet-pane .leaflet-tile-pane {
      filter: opacity(0.6) grayscale(0.8);
    }

    .leaflet-popup-content-wrapper,
    .leaflet-popup-tip {
      background: transparent;
      border-radius: 0;
      margin: 0;
      padding: 0;
    }
    .leaflet-popup-content {
      margin: 0;
      padding: 0;
    }
    .leaflet-popup-tip-container {
      display: none;
    }
    a.leaflet-popup-close-button {
      color: var(--ud-black);
      background: transparent;
    }
  }
`
const PopupContent = styled.div`
  display: flex;
  flex-direction: column;
  background: var(--ud-white);
  font-family: var(--font-text);
  color: var(--ud-black);
  padding: 1rem;
  width: 18.75rem;

  @media ${device.tabletM} {
    width: 26rem;
  }

  h3 {
    font-size: 2.25rem;
    line-height: 1.11111111;
    margin: 0 0 1rem 0;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  ul li {
    padding: 0 0 1rem 0;
    font-size: 1rem;
    line-height: 1.25;
  }

  ul li .deliveryDate {
    display: block;
    font-family: var(--font-heading);
    font-size: 1.25rem;
    line-height: 1.2;
  }

  p {
    align-self: flex-start;
    font-size: 1.125rem;
    line-height: 1.22222222;
    margin: 0 0 1rem 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
`
